import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import QuestionColumn from "./QuestionColumn";
import GradingColumn from "./GradingColumn/GradingColumn";
import AssessmentApiService from "../../services/api/assessmentAPIService";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useRollbar } from "@rollbar/react";
import "./index.css";
import Swal from "sweetalert2";

const FeedbackPage = () => {
  const rollbar = useRollbar(); // Initialize Rollbar
  const { onlineTestId, name } = useParams();
  const navigate = useNavigate();
  const [response, setResponse] = useState([]);
  const [totalPaperMark, setTotalPaperMark] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [fixingPrompts, setFixingPrompts] = useState([]);
  const [open, setOpen] = useState(false);
  const assessmentState = useSelector((state) => state.assessment);
  const assessmentPaper = assessmentState.assessmentPaper;
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  const token = userInfo.token;

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        if (onlineTestId) {
          const results = await AssessmentApiService.getStudentResults(onlineTestId, token);
          setResponse(results.questions);
          setTotalPaperMark(results.total_paper_mark);
          setFixingPrompts(results.fixing_prompts || []);
          const initialAnswers = results.questions[selectedQuestionIndex]
            ? results.questions[selectedQuestionIndex][`Question ${selectedQuestionIndex + 1}`].answers
            : [];
          setAnswers(initialAnswers); // Set initial answers
        }
      } catch (error) {
        rollbar.error("Failed to fetch results", { error, onlineTestId });
      }
    };

    if (assessmentPaper.length > 0) {
      fetchResponse();
    }
  }, [assessmentPaper.length, onlineTestId, token, rollbar, selectedQuestionIndex]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCompleteReview = async () => {
    setOpen(false);
    try {
      setIsLoading(true);
      const result = await AssessmentApiService.completeReview(onlineTestId, token);
      setIsLoading(false);
      if (result) {
        navigate(`/review-tests/${assessmentState.id}`);
      } else {
        rollbar.warning('Failed to update status', { onlineTestId });
        console.error('Failed to update status');
      }
    } catch (error) {
      rollbar.error('Error updating status', { error, onlineTestId });
      console.error('Error updating status:', error);
    }
  };

  const updateFeedbackInState = (newFeedback, id) => {
    const updatedAnswers = answers.map((answer) => {
      if (answer.id === id) {
        return {
          ...answer,
          feedback: newFeedback,
        };
      }
      return answer;
    });

    setAnswers(updatedAnswers);
  };

const updateMarksInFeedback = (newAllocatedMarks, feedbackContent) => {
  // Updated regex to capture both parts of "Mark Allocation"
  const regex = /Mark Allocation:\s*(\d+)\/(\d+)/i;
  const match = feedbackContent.match(regex);

  if (match) {
    // Replace the first capturing group (marks) with the new allocated marks
    return feedbackContent.replace(regex, `Mark Allocation: ${newAllocatedMarks}/${match[2]}`);
  } else {
    // Show toast if the pattern is not found
    Swal.fire("Error", "The feedback does not contain a valid Mark Allocation pattern. Please update the feedback manually.", "error");
    return null;
  }
};

const updateMarksInState = (newAllocatedMarks, id) => {
  const updatedAnswers = answers.map((answer) => {
    if (answer.id === id) {
      const updatedFeedback = updateMarksInFeedback(newAllocatedMarks, answer.feedback);
      if (updatedFeedback !== null) {
        return {
          ...answer,
          marks: newAllocatedMarks,
          feedback: updatedFeedback,
        };
      }
    }
    return answer;
  });

  setAnswers(updatedAnswers);
};

  useEffect(() => {
    if (response[selectedQuestionIndex]) {
      const questionKey = `Question ${selectedQuestionIndex + 1}`;
      setAnswers(response[selectedQuestionIndex][questionKey]?.answers || []);
    }
  }, [response, selectedQuestionIndex]);

  return (
    <div>
      <div className="header-style">
        <div className="info-style">
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
            {name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`Grade: ${assessmentState.grade || "N/A"}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`Subject: ${assessmentState.subject_name || "N/A"}`}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {`Marks: ${totalPaperMark}/${assessmentState.total_marks}`}
          </Typography>
          <Typography
            variant="body1"
            className={`pass-fail-style ${totalPaperMark / assessmentState.total_marks >= 0.4 ? 'pass' : 'fail'}`}
          >
            {totalPaperMark / assessmentState.total_marks >= 0.4 ? "Passed" : "Failed"}
          </Typography>
        </div>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Complete Review
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Complete Review"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to mark this review as complete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCompleteReview} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div className="question-numbers-container-style">
        {response.map((questionObj, index) => {
          const questionKey = `Question ${index + 1}`;
          const question = questionObj[questionKey]; // Access the question data by key

          if (!question) {
            return null; // Safeguard in case the question is not found
          }

          const questionNumber = index + 1;
          const marksObtained = question.total_mark || 0;  // Now accessing the correct total_mark
          const totalMarksAllocated = assessmentPaper[index]?.marks || 0;

          const percentage = totalMarksAllocated > 0 ? (marksObtained / totalMarksAllocated) * 100 : 0;

          let backgroundColor = "lightgrey";
          if (percentage > 40) {
            backgroundColor = "#a4d8a4";
          } else if (percentage > 0) {
            backgroundColor = 'pink';
          }

          if (index === selectedQuestionIndex) {
            backgroundColor = '#84bcbd';
          }

          return (
            <div
              key={index}
              className="question-number-style"
              style={{
                backgroundColor,
              }}
              onClick={() => setSelectedQuestionIndex(index)}
            >
              <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {`Question ${questionNumber}`}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {`Marks: ${marksObtained}/${totalMarksAllocated}`}
              </Typography>
            </div>
          );
        })}
      </div>

      <div className="page-style">
        {answers && (
          <>
            <QuestionColumn
              question={assessmentPaper[selectedQuestionIndex]}
              selectedQuestionIndex={selectedQuestionIndex}
            />
            {/*<AnswerColumn*/}
            {/*  urls={response[selectedQuestionIndex]?.[`Question ${selectedQuestionIndex + 1}`]?.url_answer || []} // Use the collected url_answer for the question*/}
            {/*/>*/}
            <GradingColumn
              answers={answers}
              feedback={answers.map(answer => answer.feedback).join('\n')}
              updateFeedbackInState={updateFeedbackInState}
              updateMarksInState={updateMarksInState}
              fixingPrompts={fixingPrompts}
            />
          </>
        )}
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default FeedbackPage;

import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";

const MarksTable = ({ data, topics, questions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [view, setView] = useState('topics'); // 'topics' or 'questions'

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTopicsDataForLearner = (learnerName) => {
    let topicDetails = [];

    topics.forEach(topic => {
      let totalMarks = 0;
      let totalAllocatedMarks = 0;

      questions.forEach(question => {
        if (question.topic_id === topic.id) {
          const learner = question.learners.find(l => l.name === learnerName);
          if (learner) {
            totalMarks += learner.total_marks;
            totalAllocatedMarks += (learner.percentage * learner.total_marks) / 100;
          }
        }
      });

      const topicPercentage = totalMarks > 0 ? (totalAllocatedMarks / totalMarks) * 100 : 0;
      topicDetails.push({ name: topic.name, marks: topicPercentage });
    });

    topicDetails.sort((a, b) => b.marks - a.marks);
    return topicDetails;
  };

  const getQuestionsDataForLearner = (learnerName) => {
    let questionDetails = [];

    questions.forEach((question, index) => {
      const learner = question.learners.find(l => l.name === learnerName);
      if (learner) {
        questionDetails.push({ name: `Question ${index + 1}`, marks: learner.percentage, skipped: learner.skipped });
      } else {
        questionDetails.push({ name: `Question ${index + 1}`, marks: 0, skipped: true });
      }
    });

    return questionDetails;
  };

  return (
    <Card style={{ margin: '10px 20px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={view === 'questions'}
                onChange={(e) => setView(e.target.checked ? 'questions' : 'topics')}
                name="viewToggle"
                color="primary"
              />
            }
            label={`Switch to ${view === 'questions' ? 'Topics View' : 'Questions View'}`}
          />
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mark</TableCell>
                {view === 'topics' && topics.map((topic, index) => (
                  <TableCell key={index}>{topic.name}</TableCell>
                ))}
                {view === 'questions' && questions.map((question, index) => (
                  <TableCell key={index}>{`Question ${index + 1}`}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const topicDetails = getTopicsDataForLearner(row.name);
                const questionDetails = getQuestionsDataForLearner(row.name);
                return (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.percentage !== null ? `${row.percentage.toFixed(2)}%` : 'No Data'}</TableCell>
                    {view === 'topics' && topicDetails.map((topic, index) => (
                      <TableCell key={index}>{topic.marks.toFixed(2)}%</TableCell>
                    ))}
                    {view === 'questions' && questionDetails.map((question, index) => (
                      <TableCell key={index} style={{ color: question.skipped ? 'red' : 'black' }}>
                        {question.skipped ? 'Skipped' : `${question.marks.toFixed(2)}%`}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
};

export default MarksTable;

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useNavigate, useParams } from "react-router-dom";
import "./TestResults.css";
import CourseAssessmentResultsApiService from "../../../services/api/courseAssessmentResultsApiService"; // Add your custom styling if necessary

const TestResults = () => {
    const [testResults, setTestResults] = useState([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const { courseId } = useParams(); // Assuming the route has courseId as a parameter

    // Fetch the course assessment results when the component loads
    useEffect(() => {
        const fetchTestResults = async () => {
            try {
                const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
                const token = userInfo.token;

                // Fetch the assessments for this course
                const response = await CourseAssessmentResultsApiService.getCourseAssessmentResultsByCourse(courseId, token);
                setTestResults(response);
            } catch (error) {
                console.error("Error fetching test results:", error);
            }
        };

        fetchTestResults();
    }, [courseId]);

    // Utility function to get initials for Avatar
    const getInitials = (name) => {
        if (!name) return '';
        return name.slice(0, 2).toUpperCase();  // Get the first two letters of the name
    };

    // Handle row click event to navigate to test results for the selected course
    const handleRowClick = (params) => {
        const selectedCourseId = params.row.course;  // Assuming the course is passed as an object
        navigate(`/test-results/${selectedCourseId}`);
    };

    // DataGrid column definitions
    const columns = [
        {
            field: "exam_name",
            headerName: "Assessment Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ width: 32, height: 32, marginRight: 1, backgroundColor: params.row.status === "Marking" ? 'orange' : '#5f9fa1' }}>
                        {getInitials(params.value.name)}
                    </Avatar>
                    <span>{params.value.name}</span>
                </div>
            ),
            valueGetter: (params) => params.row.exam_name, // Assuming exam_paper contains the exam name
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
        },
        {
            field: "average_mark",
            headerName: "Average %",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
            valueGetter: (params) => params.row.status === "Marking" ? "Not Available" : `${params.row.average_mark}%`,
        },
        {
            field: "passed_learners",
            headerName: "Passed Learners",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
            valueGetter: (params) => params.row.status === "Marking" ? "Not Available" : params.row.passed_learners,
        },
        {
            field: "failed_learners",
            headerName: "Failed Learners",
            flex: 1,
            align: "left",
            headerClassName: "centered-header",
            valueGetter: (params) => params.row.status === "Marking" ? "Not Available" : params.row.failed_learners,
        },
        {
            field: "missed_tests",
            headerName: "Missed Test",
            flex: 1.5,
            align: "left",
            headerClassName: "centered-header",
            valueGetter: (params) => params.row.total_learners - params.row.total_tests_accepted,
        },
    ];

    // Filter test results based on search input
    const filteredTestResults = testResults
        .filter(
            (result) =>
                result.exam_name.toLowerCase().includes(searchText.toLowerCase()) || // Assuming exam_paper has a name field
                result.average_mark.toString().includes(searchText.toLowerCase())
        );

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "20px",
                flexDirection: "column",
                gap: "30px",
            }}>
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: { width: "200px", borderRadius: 25 },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />

                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={filteredTestResults}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        onRowClick={handleRowClick}  // Navigate when a row is clicked
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: '10px',
                            border: "none",
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default TestResults;

import React, { useEffect, useState } from "react";
import "./header.css";
import logoLight from "../../../assets/educase-logo-light2.png";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header = () => {
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [showAIDropdown, setShowAIDropdown] = useState(false);
  const [showMobileProductDropdown, setShowMobileProductDropdown] = useState(false);
  const [showMobileAIDropdown, setShowMobileAIDropdown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleProductMouseEnter = () => {
    setShowProductDropdown(true);
  };

  const handleProductMouseLeave = () => {
    setTimeout(() => {
      if (!document.querySelector('.landing-page-header-dropdown-card:hover')) {
        setShowProductDropdown(false);
      }
    }, 100);
  };

  const handleAIMouseEnter = () => {
    setShowAIDropdown(true);
  };

  const handleAIMouseLeave = () => {
    setTimeout(() => {
      if (!document.querySelector('.landing-page-header-dropdown-card-ai:hover')) {
        setShowAIDropdown(false);
      }
    }, 100);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMobileProductDropdown = () => {
    setShowMobileProductDropdown(!showMobileProductDropdown);
  };

  const toggleMobileAIDropdown = () => {
    setShowMobileAIDropdown(!showMobileAIDropdown);
  };

  return (
      <header className={"landing-page-header"}>
        <div className="landing-page-header-container">
          <div className="landing-page-header-logo-nav">
            <Link to="/">
                <img
                   className="landing-page-header-logo"
                   src={logoLight}
                   alt="Educase Logo"
                />
             </Link>
            {/* Hamburger menu for small screens */}
            <button className="landing-page-header-menu-toggle" onClick={toggleMenu}>
              <MenuIcon style={{ fontSize: "30px", color: "#5f9ea0" }} />
            </button>

            <nav className={`landing-page-header-mobile-nav ${menuOpen ? "open" : ""}`}>
              <button className="landing-page-header-close-menu" onClick={closeMenu}>
                <CloseIcon style={{ fontSize: "30px", color: "#ffffff" }} />
              </button>
              <ul>
                <li>
                  <a href="#" onClick={toggleMobileProductDropdown}>
                    Product <span
                      className={`landing-page-mobile-header-arrow ${showMobileProductDropdown ? "open" : ""}`}></span>
                  </a>
                  {showMobileProductDropdown && (
                      <ul className="landing-page-header-mobile-dropdown-list">
                        <li><a href="#">Marking</a></li>
                        <li><a href="#">Assessment Creation Tool</a></li>
                        <li><a href="#">AI Tools</a></li>
                        <li><a href="#">Online Testing Platform</a></li>
                        <li><a href="#">Generate Report Cards</a></li>
                        <li><a href="#">Metrics and Stats</a></li>
                      </ul>
                  )}
                </li>
                <li>
                  <a href="#" onClick={toggleMobileAIDropdown}>
                    Resources <span
                      className={`landing-page-mobile-header-arrow ${showMobileAIDropdown ? "open" : ""}`}></span>
                  </a>
                  {showMobileAIDropdown && (
                      <ul className="landing-page-header-mobile-dropdown-list">
                        <li><a href="#">Help Center</a></li>
                        <li><a href="#">Book a Free Training</a></li>
                        <li><a href="#">Blog</a></li>
                      </ul>
                  )}
                </li>
                <li><a href="#">Pricing</a></li>
                <li><Link to="/auth/login">Login</Link></li>
                <li><Link to="/auth/registration">Sign up</Link></li>
              </ul>
            </nav>

            {/* Desktop nav for larger screens */}
            <nav className="landing-page-header-nav">
              <ul>
                <li className="landing-page-header-nav-item">
                  <div
                      className="landing-page-header-dropdown-trigger"
                      onMouseEnter={handleProductMouseEnter}
                      onMouseLeave={handleProductMouseLeave}
                  >
                    <a href="#">Product</a>
                    <span className="landing-page-header-arrow"></span>
                    {showProductDropdown && (
                        <div
                            className="landing-page-header-dropdown-card"
                            onMouseEnter={handleProductMouseEnter}
                            onMouseLeave={handleProductMouseLeave}
                        >
                          <div className="landing-page-header-dropdown-content">
                            <div className="landing-page-header-dropdown-column">
                              <div className="landing-page-header-product-list nowrap">
                                <h3>PRODUCT</h3>
                                <ul>
                                  <li className="standout"><a href="#">Marking</a></li>
                                  <li><Link to="/create-assessment-tool">Assessment Creation Tool</Link></li>
                                  <li><a href="#">AI Tools</a></li>
                                  <li><a href="#">Generate Report Cards</a></li>
                                  <li><a href="#">Metrics and Stats</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </li>
                <li className="landing-page-header-nav-item">
                  <div
                      className="landing-page-header-dropdown-trigger"
                      onMouseEnter={handleAIMouseEnter}
                      onMouseLeave={handleAIMouseLeave}
                  >
                    <a href="#">Resources</a>
                    <span className="landing-page-header-arrow"></span>
                    {showAIDropdown && (
                        <div
                            className="landing-page-header-dropdown-card-ai"
                            onMouseEnter={handleAIMouseEnter}
                            onMouseLeave={handleAIMouseLeave}
                        >
                          <div className="landing-page-header-dropdown-content">
                            <div className="landing-page-header-dropdown-column">
                              <div className="landing-page-header-product-list">
                                <h3>Educase Blog</h3>
                                <ul>
                                  <li><a href="#">Help Center</a></li>
                                  <li><a href="#">Book a Free Training</a></li>
                                  <li><a href="#">Blog</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </li>
                <li><a href="#">Pricing</a></li>
              </ul>
            </nav>
          </div>
          <div className="landing-page-header-auth-buttons">
            <Link to="/auth/login" className="landing-page-header-login-button">Login</Link>
            <Link to="/auth/registration" className="landing-page-header-try-button">Try for free</Link>
          </div>
        </div>
      </header>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Avatar, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import UploadIcon from "@mui/icons-material/Upload";
import ImageIcon from "@mui/icons-material/Image";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ImageUploadComponent from "./ImageUploaderComponent/ImageUploader";
import CourseApiService from "../../../services/api/courseAPIService";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import DialogActions from "@mui/material/DialogActions";

const UploadLearnerScripts = () => {
  const { courseId, subject, grade, assessmentId } = useParams();
  const [learners, setLearners] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLearners, setFilteredLearners] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState({});
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [viewImagesModalOpen, setViewImagesModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedLearnerId, setSelectedLearnerId] = useState(null);
  const [learnersWithoutImages, setLearnersWithoutImages] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;

  useEffect(() => {
    const fetchLearners = async () => {
      try {
        const response = await CourseApiService.getLearnersForCourse(courseId, token);
        if (response && response.status === 200) {
          const learnersData = await response.json();
          setLearners(learnersData);
          setFilteredLearners(learnersData);
        } else {
          console.error("Failed to fetch learners.");
        }
      } catch (error) {
        console.error("Error fetching learners:", error);
      }
    };

    fetchLearners();
  }, [courseId, token]);

  const createInitials = (firstName, lastName) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = learners.filter((learner) =>
      `${learner.first_name} ${learner.last_name}`.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLearners(filtered);
  };

  const handleImageUpload = (learnerId, url) => {
    setUploadedUrls((prevUrls) => ({
      ...prevUrls,
      [learnerId]: prevUrls[learnerId] ? [...prevUrls[learnerId], url] : [url],
    }));
  };

  const handleCloseUploadModal = () => {
    setImageModalOpen(false);
    setSelectedLearnerId(null);
  };

  const handleCloseViewImagesModal = () => {
    setViewImagesModalOpen(false);
    setSelectedLearnerId(null);
  };

  const handleOpenUploadModal = (learnerId) => {
    setSelectedLearnerId(learnerId);
    setImageModalOpen(true);
  };

  const handleOpenImageModal = (learnerId) => {
    const images = uploadedUrls[learnerId] || [];
    if (images.length > 0) {
      setSelectedImages(images);
      setViewImagesModalOpen(true);
    }
  };

  const isScriptUploaded = (learnerId) => uploadedUrls[learnerId] && uploadedUrls[learnerId].length > 0;

const handleSubmitAllScripts = async () => {
    const submissions = learners.map((learner) => ({
        learner_id: learner.id,
        answer_urls: uploadedUrls[learner.id] || [],
        attempted: !!(uploadedUrls[learner.id] && uploadedUrls[learner.id].length > 0),
    }));
    const learnersWithoutUploads = [];
    try {
        // Send all the learners' submissions in one batch to the backend
        await OnlineTestApiService.submitLearnerScripts(
            courseId,
            assessmentId,
            submissions,
            token
        );
    } catch (error) {
        console.error('Error submitting all learner scripts:', error);
    }


    // After the submission, show the modal for learners who did not upload
    if (learnersWithoutUploads.length > 0) {
        setLearnersWithoutImages(learnersWithoutUploads);
        setAlertOpen(true); // Show alert with missing learners
    }
};


  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderCell: (params) => (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center">
<Avatar
  sx={{
    width: {
      xs: 24,  // Small size for extra small screens (mobile)
      sm: 28,  // Medium size for small screens (tablets)
      md: 32,  // Default size for medium screens (desktops)
      lg: 36,  // Larger size for large screens (larger desktops)
      xl: 40,  // Largest size for extra large screens
    },
    height: {
      xs: 24,  // Small size for extra small screens
      sm: 28,  // Medium size for small screens
      md: 32,  // Default size for medium screens
      lg: 36,  // Larger size for large screens
      xl: 40,  // Largest size for extra large screens
    },
    marginRight: 1,
    backgroundColor: "#5f9ea0",
    color: "white",
  }}
>
  {createInitials(params.row.first_name, params.row.last_name)}
</Avatar>

            <span>{`${params.row.first_name} ${params.row.last_name}`}</span>
          </Box>
          {isScriptUploaded(params.row.id) && (
            <CheckCircleIcon sx={{ color: "#5f9ea0" }} />
          )}
        </Box>
      ),
    },
    {
      field: "upload",
      headerName: "Upload",
      flex: 1,
      sortable: false, // Disable sorting for this column
      disableColumnMenu: true, // Disable column menu (three dots)
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => handleOpenUploadModal(params.row.id)}
        >
          <UploadIcon sx={{ color: "#5f9ea0" }} />
        </IconButton>
      ),
    },
    {
      field: "viewImages",
      headerName: "Images",
      flex: 1,
      sortable: false, // Disable sorting for this column
    disableColumnMenu: true, // Disable column menu (three dots)
      renderCell: (params) =>
        isScriptUploaded(params.row.id) ? (
          <IconButton
            color="primary"
            onClick={() => handleOpenImageModal(params.row.id)}
          >
            <ImageIcon sx={{ color: "#5f9ea0" }} />
          </IconButton>
        ) : null,
    },
  ];

  return (
      <div style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "5px" }}>

        <div style={{ margin: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

<TextField
  id="search"
  placeholder="Search Learners..."
  size="small"
  value={searchTerm}
  onChange={handleSearchChange}
  variant="outlined"
  InputProps={{
    style: { borderRadius: 25 },
  }}
  sx={{
    width: { xs: "100%", sm: "300px", md: "400px", lg: "500px" }, // Adjust width based on screen size
    "& .MuiOutlinedInput-root": {
      borderRadius: "25px",
      borderColor: "#d0d4d9",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d0d4d9",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b3b3b3",
    },
    "& .MuiInputBase-input": {
      padding: "10px",
      color: "#5f9ea0",
    },
  }}
/>

<Button
  variant="contained"
  color="primary"
  onClick={handleSubmitAllScripts}
    disabled={learners.length === 0 || learners.some(isScriptUploaded)} // Enable if at least one learner has uploaded images
  sx={{
    width: { xs: "200px", sm: "150px", md: "100px" }, // Adjust width based on screen size
    fontSize: { xs: "12px", sm: "14px", md: "16px" }, // Adjust font size for smaller screens
    padding: { xs: "10px", sm: "12px", md: "14px" }, // Adjust padding
    marginTop: 0,
  }}
>
  Submit
</Button>
                </div>
      <Box
  sx={{
    overflowY: "hidden",
    height: "auto",
    padding: {
      xs: 0,  // padding for extra small screens (mobile)
      sm: 0,  // padding for small screens (tablets)
      md: 1,  // padding for medium screens (desktop)
      lg: 2,  // padding for large screens (larger desktops)
      xl: 2,  // padding for extra large screens
    },
  }}
>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            backgroundColor: "white",
            borderRadius: "16px",
            border: "2px solid #cfd8dc",
            boxShadow: "none",
            width: "95%",
            minHeight: "80vh",
          }}
        >
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredLearners.map((learner) => ({
                ...learner,
                id: learner.id,
              }))}
              columns={columns}
              pageSize={10}
              rowHeight={60}
              headerHeight={56}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              autoHeight
              getRowClassName={(params) =>
                isScriptUploaded(params.row.id) ? "uploaded-row" : ""
              }
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                border: "none",
                "& .uploaded-row": {
                  backgroundColor: "#e0f7fa",
                },
              }}
            />
          </div>
        </Box>
      </Box>

      {/* Image Upload Modal */}
      <Dialog open={imageModalOpen} onClose={handleCloseUploadModal} maxWidth="md">
        <DialogContent>
          {selectedLearnerId && (
            <ImageUploadComponent
              subject={subject}
              grade={grade}
              assessmentId={assessmentId}
              learnerId={selectedLearnerId}
              onImageUpload={(url) => handleImageUpload(selectedLearnerId, url)}
              onImageDelete={() => {}}
              existingImages={[]}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Image View Modal */}
      {selectedImages.length > 0 && (
        <Dialog open={viewImagesModalOpen} onClose={handleCloseViewImagesModal} maxWidth="md">
          <DialogTitle>Uploaded Images</DialogTitle>
          <DialogContent>
            <ImageUploadComponent
              subject={subject}
              grade={grade}
              assessmentId={assessmentId}
              learnerId={selectedLearnerId}
              onImageUpload={(url) => handleImageUpload(selectedLearnerId, url)}
              onImageDelete={() => {}}
              existingImages={selectedImages}
            />
          </DialogContent>
        </Dialog>
      )}

          {/* Alert for learners marked as "Not Attempted" */}
{/* Alert for learners' submission details */}
<Dialog open={alertOpen} onClose={() => setAlertOpen(false)} maxWidth="sm">
  <DialogTitle>Missing Scripts</DialogTitle>
  <DialogContent>
    <Alert severity="warning">
      The following learners did not upload their scripts:
      <ul>
        {learnersWithoutImages.map((learner) => (
          <li key={learner.id}>{`${learner.first_name} ${learner.last_name}`}</li>
        ))}
      </ul>
    </Alert>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setAlertOpen(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>


      </div>
  );
};

export default UploadLearnerScripts;
import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import getConfig from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { populateTopics } from "../../reducers/topicsQuestionBank";
import { addSelectedTopic, removeSelectedTopic } from "../../reducers/selectedTopics";

const SelectTopicsStep = ({ grade, subject, subject_name, handleNextStep, handlePreviousStep }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const topicsState = useSelector((state) => state.topics || []);
    const selectedTopicsState = useSelector((state) => state.selectedTopics || []);
    const scrollRef = useRef(null);  // useRef to keep track of the element to scroll

    useEffect(() => {
        fetchData();
    }, [grade, subject]);

    const fetchData = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();

        const response = await fetch(`${config.api.base_url}/topics/topics_by_question_bank/?grade=${grade}&subject=${subject}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
        });

        if (response.ok) {
            const res = await response.json();
            dispatch(populateTopics(res));
            setLoading(false);

            // Auto-scroll to the topics section after they are fetched
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            const res = await response.json();
            console.error("Error fetching topics:", res.error);
            setLoading(false);
        }
    };

    const handleTopicToggle = (event, questionBankId, topic) => {
        const isSelected = selectedTopicsState.some(
            (selectedItem) => selectedItem.questionBankId === questionBankId && selectedItem.topic.id === topic.id
        );
        if (isSelected) {
            dispatch(removeSelectedTopic({ questionBankId, topic }));
        } else {
            dispatch(addSelectedTopic({ questionBankId, topic }));
        }
    };

    const handleSelectAllToggle = (event, questionBankId) => {
        const questionBank = topicsState.find(bank => bank.question_bank_id === questionBankId);
        const allSelected = questionBank.topics.every(topic =>
            selectedTopicsState.some(selectedItem =>
                selectedItem.questionBankId === questionBank.question_bank_id && selectedItem.topic.id === topic.id
            )
        );

        questionBank.topics.forEach(topic => {
            if (allSelected) {
                dispatch(removeSelectedTopic({ questionBankId, topic }));
            } else {
                dispatch(addSelectedTopic({ questionBankId, topic }));
            }
        });
    };

    if (loading) {
        return <Typography>Loading...</Typography>;  // Show loading message while fetching
    }

    return (
        <Card sx={{ border: "1px solid #5f9ea0", borderRadius: 2, boxShadow: 'none', mt: 2, margin: "20px" }}>
            <CardContent ref={scrollRef}> {/* Set the ref on the CardContent for scrolling */}
                <Typography variant="h6" sx={{ mb: 2, mt: 0, ml: 1, color: '#5f9ea0' }}>Choose Topics</Typography>

                {/* Render Question banks and topics */}
                {topicsState.map((questionBank) => {
                    const allSelected = questionBank.topics.every(topic =>
                        selectedTopicsState.some(selectedItem =>
                            selectedItem.questionBankId === questionBank.question_bank_id && selectedItem.topic.id === topic.id
                        )
                    );

                    return (
                        <Box key={questionBank.question_bank_id} sx={{ mb: 4 }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="left">
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: { xs: '12px', sm: '18px', md: '20px', lg: '24px' },
                                        textAlign: { xs: 'left', sm: 'left' },
                                        margin: { xs: '10px 0', sm: '15px 0', md: '20px 0' },
                                      }}
                                    >
                                      {questionBank.question_bank_name}
                                    </Typography>
                                </Box>
                                <Box>
                                 <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={allSelected}
                                      onChange={(event) => handleSelectAllToggle(event, questionBank.question_bank_id)}
                                      color="primary"
                                      sx={{
                                        transform: { xs: 'scale(0.8)', sm: 'scale(1)', md: 'scale(1.1)' },
                                        marginRight: { xs: '0', sm: '10px', md: '15px' },
                                      }}
                                    />
                                  }
                                  label="Select All"
                                  sx={{
                                    fontSize: { xs: '8px', sm: '10px', md: '16px' },
                                    marginLeft: { xs: '0', sm: '10px', md: '15px' },
                                  }}
                                />
                                </Box>
                            </Box>

                            <Grid container spacing={2}>
                                {questionBank.topics.map((topic) => {
                                    const isSelected = selectedTopicsState.some(
                                        (selectedItem) =>
                                            selectedItem.questionBankId === questionBank.question_bank_id &&
                                            selectedItem.topic.id === topic.id
                                    );
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={topic.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onChange={(event) => handleTopicToggle(event, questionBank.question_bank_id, topic)}
                                                        color="primary"
                                                    />
                                                }
                                                label={topic.name}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    );
                })}

                {/* Back and Next Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: 'primary', color: 'white' }}
                        onClick={handlePreviousStep}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: 'primary', color: 'white' }}
                        onClick={handleNextStep}
                    >
                        Next
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SelectTopicsStep;

import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import "./assessmentTable.css";
import { useDispatch } from "react-redux";
import { populateState } from "../../reducers/assessment";
import { resetState } from "../../reducers/componentStates";
import { addSelectedTopic } from "../../reducers/selectedTopics";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import AssessmentApiService from "../../services/api/assessmentAPIService";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyTestLinkDialog from "../CopyTestLinkDialog/CopyTestLinkDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TaskIcon from '@mui/icons-material/Task';
import Avatar from "@mui/material/Avatar";

const EXAM_PAPER_TYPES = {
    "exam": "Exam",
    "test": "Test",
    "baseline_test": 'Baseline Test',
    "practice_test": 'Practice Test'
};

const AssessmentTable = ({ assessments, setAssessments }) => {
    const [searchText, setSearchText] = useState("");
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const [openModal, setOpenModal] = useState(false);
    const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
    const { token } = parsedUserInfo;
    const dispatch = useDispatch();

    // Inside your component:
    const navigate = useNavigate();

    const fetchFullExamData = async (assessment_id) => {
        try {
            const response = await AssessmentApiService.fetchAssessment(assessment_id, token);
            if (response) {
                return await response;
            } else {
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch assessment data. Please try again later.'
                });
                return null;
            }
        } catch (error) {
            console.error("Error fetching full exam data:", error);
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again later.'
            });
            return null; // Handle fetch error
        }
    };

    const handleEdit = async (paperId) => {
        try {
            const assessment = await fetchFullExamData(paperId);

            if (assessment) {
                dispatch(populateState(assessment));
                dispatch(resetState());

                await dispatch(addSelectedTopic(assessment.topics || []));

                navigate("/assessment-details");
            } else {
                console.error("Failed to fetch full assessment data.");
            }
        } catch (error) {
            console.error("Error during handleEdit:", error);
        }
    };

    const handleCopy = (id) => {
        setSelectedAssessmentId(id);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleMarkPaper = async (assessmemntId) => {
        try {
            const assessment = await fetchFullExamData(assessmemntId);

            if (assessment) {
                dispatch(populateState(assessment));
                dispatch(resetState());

                dispatch(addSelectedTopic(assessment.topics || []));

                navigate("/manual-marking");
            } else {
                console.error("Failed to fetch full assessment data.");
            }
        } catch (error) {
            console.error("Error during handleEdit:", error);
        }
    }

    const getInitials = (name) => {
        if (!name) return "";
        return name.slice(0, 2).toUpperCase(); // Get the first two letters of the name
    };


    const handleDelete = async (assessmentId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const response = await AssessmentApiService.deleteAssessment(assessmentId, token);
                if (response.ok) {
                    Swal.fire(
                        'Deleted!',
                        'The assessment has been deleted.',
                        'success'
                    );

                    // Update the assessments array to remove the deleted item
                    setAssessments(prevAssessments => prevAssessments.filter(assessment => assessment.id !== assessmentId));
                } else {
                    const res = await response.json();
                    console.error("Error deleting assessment:", res.error);
                    Swal.fire(
                        'Error!',
                        'There was an error deleting the assessment. Please try again.',
                        'error'
                    );
                }
            }
        } catch (error) {
            console.error("Error deleting assessment:", error);
            await Swal.fire(
                'Error!',
                'An unexpected error occurred. Please try again later.',
                'error'
            );
        }
    };

    const columns = [
            {
                field: "name",
                headerName: "Name",
                flex: 2,
                align: "left",
                headerClassName: "centered-header",
                renderCell: (params) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ width: 32, height: 32, marginRight: 1, backgroundColor: "#5f9fa1" }}>
                            {getInitials(params.value)}
                        </Avatar>
                        <span>{params.value}</span>
                    </div>
                ),
            },
        {
            field: "subject",
                headerName: "Subject",
                flex: 0.5,
                align: "left",
                headerClassName: "centered-header",
                valueFormatter: (subject) => subject.value.name,
            },
            {
                field: "grade",
                headerName: "Grade",
                flex: 0.5,
                align: "left",
                headerClassName: "centered-header",
            },
            {
                field: "type",
                headerName: "Type",
                flex: 0.5,
                align: "left",
                headerClassName: "centered-header",
                valueFormatter: (params) => EXAM_PAPER_TYPES[params.value] || params.value,
            },
            {
                field: "assessment_date",
                headerName: "Date",
                flex: 0.5,
                align: "left",
                headerClassName: "centered-header"
            },
            {
                field: "actions",
                headerName: "",
                width: 40,
                align: "center",
                sortable: false,
                renderCell: (params) => (
                    <>
                        <IconButton onClick={(e) => handleMenuClick(e, params.row)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                style: {
                                    border: '1px solid #87b6b7',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <MenuItem onClick={() => handleEdit(selectedRow?.id)}>
                                <ModeEditOutlinedIcon fontSize="small" style={{ marginRight: 8 }} />
                                Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleCopy(selectedRow?.id)}>
                                <ContentCopyIcon fontSize="small" style={{ marginRight: 8 }} />
                                Copy
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(selectedRow?.id)}>
                                <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                                Delete
                            </MenuItem>
                        </Menu>
                    </>
                ),
            },
        ];

    // Filter assessments based on search text
    const filteredAssessments = assessments.filter(
        (assessment) =>
            assessment.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.grade.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.subject.name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.type.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px", marginBottom: "20px" }}>
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: { width: "100%", borderRadius: 25 },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />


            </div>


            <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                    rows={filteredAssessments}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: '10px',
                        boxShadow: 0,
                        border: "none",
                        [`& .${"MuiDataGrid-cell"}:focus-within`]: {
                            outline: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "1px solid #b3d1e6",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "1px solid #b3d1e6",
                        },
                    }}
                />
            </div>
            <CopyTestLinkDialog
                open={openModal}
                onClose={handleClose}
                selectedAssessmentId={selectedAssessmentId}
                parsedUserInfo={parsedUserInfo}
            />

        </>
    );
};

export default AssessmentTable;
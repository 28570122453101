import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Chip, Typography } from "@mui/material";
import "../OnboardingPage.css";
import { useNavigate } from "react-router-dom";
import CourseApiService from "../../../services/api/courseAPIService";

const TeacherOnboarding = () => {
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [grades, setGrades] = useState([{ grade: '', selectedSubjects: [] }]);
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    // Available subjects by grade
    const subjectsByGrade = {
        '4-6': [
            'Natural Science and Technology',
            'Life Skills',
            'Economics Management Science',
            'English First Language',
            'English First Additional Language',
            'Mathematics',
            'Robotics',
            'Accounting'
        ],
        '7-9': [
            'Natural Science',
            'Technology',
            'Social Sciences',
            'Economics Management Science',
            'Mathematics',
            'English First Language',
            'English First Additional Language',
            'Life Orientation'
        ],
        '10-12': [
            'Business Studies',
            'Accounting',
            'Chemistry',
            'Mathematics',
            'Mathematics Literacy',
            'Physical Science',
            'Economics',
            'Life Science',
            'History',
            'Geography'
        ]
    };

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setEmail(user.email || '');
            setToken(user.token);  // Set token for authentication
        }
    }, []);

    const handleGradeClick = (gradeValue) => {
        if (selectedGrades.length < 4 && !selectedGrades.includes(gradeValue)) {
            setSelectedGrades([...selectedGrades, gradeValue]);
            setGrades([...grades, { grade: gradeValue, selectedSubjects: [] }]);
        }
    };

    const handleSubjectToggle = (gradeValue, subject) => {
        const newGrades = [...grades];
        const gradeIndex = newGrades.findIndex((g) => g.grade === gradeValue);

        if (gradeIndex !== -1) {
            const selectedSubjects = newGrades[gradeIndex].selectedSubjects;

            if (selectedSubjects.includes(subject)) {
                newGrades[gradeIndex].selectedSubjects = selectedSubjects.filter(s => s !== subject);
            } else if (selectedSubjects.length < 4) {
                newGrades[gradeIndex].selectedSubjects = [...selectedSubjects, subject];
            }

            setGrades(newGrades);
        }
    };

    const renderSubjectChips = (grade) => {
        if (grade >= 4 && grade <= 6) return subjectsByGrade['4-6'];
        if (grade >= 7 && grade <= 9) return subjectsByGrade['7-9'];
        if (grade >= 10 && grade <= 12) return subjectsByGrade['10-12'];
        return [];
    };

    const handleBackClick = () => {
        navigate('/onboarding-page');
    };

    // Submit the selected grades and subjects to the backend
const handleSubmit = async () => {
    // Prepare the payload for multiple course creation
    const coursesPayload = grades
        .filter(grade => grade.selectedSubjects.length > 0)
        .map(grade => ({
            grade: grade.grade,  // Use the actual grade number
            subjects: grade.selectedSubjects.map(subject => subject)  // Assuming subject is the subject name
        }));

    try {
        const startDate = new Date().toISOString().split('T')[0];  // Example start date

        // Call the API method to create multiple courses
        if (coursesPayload.length === 1) {
            // Single course case
            const singleCourse = {
                course_name: `${coursesPayload[0].subjects[0]} Grade ${coursesPayload[0].grade}`,  // Subject Name Grade
                grade: coursesPayload[0].grade,  // Use the actual grade here
                subject_name: coursesPayload[0].subjects[0],  // Assuming only one subject per course
                start_date: startDate,  // Single start date
                end_date: startDate  // You can adjust this logic if needed
            };
            await CourseApiService.createCourse(singleCourse, token);  // API call for single course
        } else {
            // Multiple course case
            const courses = coursesPayload.map(course => ({
                course_name: `${course.subjects[0]} Grade ${course.grade}`,  // Subject Name Grade
                grade: course.grade,  // Use the actual grade here
                subject_name: course.subjects[0],  // Assuming only one subject per course (you can adjust)
                start_date: startDate,  // Single start date
                end_date: startDate  // You can adjust this logic if needed
            }));
            await CourseApiService.createCourse(courses, token);  // API call for multiple courses
        }

        alert("Courses created successfully!");
        navigate('/home');
    } catch (error) {
        console.error("Error creating courses:", error);
        alert("An error occurred while creating courses.");
    }
};

    return (
        <div className="onboarding-card-container">
            <Card
                className="onboarding-card-educase"
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '40px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' },
                    overflowY: 'auto',
                }}
            >
                <CardContent className="card-content-educase">
                    <p className="title-educase">
                        Perfect! Now let's choose subjects and grades
                    </p>
                    <p className="subtitle-educase">
                        You can select up to 4 grades and assign a maximum of 4 subjects for each grade.
                    </p>

                    <h3 className="input-label-educase">Add Grades with Their Associated Subjects</h3>

                    {/* Grade Chips */}
                    <Typography variant="h6" className="input-label-educase">
                        Select a grade to add subjects:
                    </Typography>

                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                        {[4, 5, 6, 7, 8, 9, 10, 11, 12].map((gradeValue) => (
                            <Chip
                                key={gradeValue}
                                label={`Grade ${gradeValue}`}
                                clickable
                                onClick={() => handleGradeClick(gradeValue)}
                                color={selectedGrades.includes(gradeValue) ? "primary" : "default"}
                                disabled={selectedGrades.length >= 4} // Disable chip when 4 grades are already selected
                            />
                        ))}
                    </Box>

                    {/* Display subjects for each selected grade */}
                    {selectedGrades.map((gradeValue, index) => (
                        <Box key={index} sx={{ mt: 3 }}>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {`${index + 1}. Add subjects for Grade ${gradeValue}`}
                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                                {renderSubjectChips(gradeValue).map((subject) => (
                                    <Chip
                                        key={subject}
                                        label={subject}
                                        clickable
                                        onClick={() => handleSubjectToggle(gradeValue, subject)}
                                        color={grades.find((g) => g.grade === gradeValue)?.selectedSubjects.includes(subject) ? "primary" : "default"}
                                        disabled={grades.find((g) => g.grade === gradeValue)?.selectedSubjects.length >= 4 && !grades.find((g) => g.grade === gradeValue)?.selectedSubjects.includes(subject)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}

                    <Box mt={4} display="flex" justifyContent="space-between">
                        {/* Back Button */}
                        <Button
                            variant="outlined"
                            onClick={handleBackClick}
                            sx={{ color: "primary", width: "100px", fontSize: "16px", borderRadius: "16px" }}
                        >
                            Back
                        </Button>

                        {/* Submit Button */}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}  // Submit the selected subjects and grades
                            sx={{ backgroundColor: "primary", color: "white", width: "100px", fontSize: "16px", borderRadius: "16px" }}
                        >
                            Submit
                        </Button>
                    </Box>

                    {/* Signed-in message */}
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body3" sx={{ color: '#777', textAlign: 'left' }}>
                            You're signed in as {email}. <a href="/signout" style={{ color: '#31c0e8', textDecoration: 'none', fontSize: '13px' }}>Sign out</a>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default TeacherOnboarding;

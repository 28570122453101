import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const TopLowestMark = ({ highest, lowest }) => {
  const styles = {
    performer: { marginBottom: '20px' },
    highest: { color: 'green', marginBottom: '10px' },
    lowest: { color: 'pink' },
    name: { fontWeight: 'bold', fontSize: '1rem' },
    mark: { fontSize: '1.1rem' }
  };

  return (
    <Card style={{ margin: '10px 30px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Typography variant="h6">Top Performer</Typography>
        <Typography style={styles.highest}>
          <span style={styles.name}>{highest.name}</span>: <span style={styles.mark}>{highest.percentage}%</span>
        </Typography>
        <Typography variant="h6">Lowest Performer</Typography>
        <Typography style={styles.lowest}>
          <span style={styles.name}>{lowest.name}</span>: <span style={styles.mark}>{lowest.percentage}%</span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TopLowestMark;

import React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import metrics from "../../../assets/metrics.png";
import recording from "../../../assets/recording.png";
import grading from "../../../assets/grading.png";

import "./features.css";
import DeleteIcon from "@mui/icons-material/Delete";

const Features = () => {
  return (
    <div className="features-section">
      <h3 className="features-section__header">
        Seamlessly manage your testing process
      </h3>
      <div className="features-section__cards-container">
        {/* Create Assessments Card */}
        <div className="features-section__card custom-card">
          <h4>Use AI to create personalised tests.</h4>
<p>Create personalised question papers with AI in just 10 minutes, tailored to your students' needs.</p>

         <div className="custom-card__annotation">

        <Card style={{
    backgroundColor: 'white',
    borderRadius: '20px',
    marginBottom: '50px',
    position: 'relative',
    height: '95px',
    transform: 'rotate(3deg)',
    transformOrigin: 'top right'
  }}
        >
            <CardContent className="ms-garcia-card__content">
                <div className="ms-garcia-card__header">
                <Avatar sx={{ bgcolor: '#5f9ea0', width: 28, height: 28 }}>Ga</Avatar>
                <h4 className="ms-garcia-card__name">Ms. Garcia</h4>
                <DeleteIcon className="ms-garcia-card__delete-icon" />
                </div>
       <p
  style={{
      color: "#2c3e50",
      fontSize: "0.8em",
      margin: "0 0 30px 0",
      textAlign: "left",
  }}
       >
           Create multiple-choice questions on Othello to test character motivations.
       </p>

            </CardContent>
        </Card>
                     <Card style={{
    backgroundColor: 'white',
    borderRadius: '20px',
    marginBottom: '20px',
    position: 'relative',
    height: '95px',
    transform: 'rotate(-3deg)',
    transformOrigin: 'top left'
  }}>
             <CardContent className="ms-garcia-card__content">
        <div className="ms-garcia-card__header">
            <Avatar sx={{ bgcolor: '#5f9ea0', width: 28, height: 28 }}>Zu</Avatar>
          <h4 className="ms-garcia-card__name">Mrs. Zulu</h4>
          <DeleteIcon className="ms-garcia-card__delete-icon" />
        </div>
          <p   style={{
      color: "#2c3e50",
      fontSize: "0.8em",
      margin: "0 0 30px 0",
      textAlign: "left",
  }}>
          Create 10 algebra questions to test solving linear equations.
        </p>
      </CardContent>
      </Card>
      </div>
    </div>
        {/* Upload Scripts for Marking Card */}
          <div className="features-section__card">
              <h4 className="">Upload Student Scripts for Fast, Automated Marking</h4>
              <p> Upload students' scripts for fast, automated marking. Our system processes submissions saving you time. </p>


                          <img src={grading} alt="grading" className="grading-image" />


          </div>

          {/* Feedback Card */}
        <div className="features-section__card">
            <h4 className=" ">Detailed, Actionable Feedback for Every Student</h4>
<p>We offer detailed feedback to learners, with the option to listen in different languages for better
    understanding.</p>
            <Card style={{
                backgroundColor: 'white',
    borderRadius: '20px',
    height: '150px',
    width: '180px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2), 0px -2px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    alignSelf: 'flex-start'
  }}>
      <CardContent className="personalised-feedback-card content">
        <div className="ms-garcia-card__header">
            <Avatar sx={{ bgcolor: '#5f9ea0', width: 28, height: 28 }}>Ma</Avatar>
            <h4 className="ms-garcia-card__name">T. Masina</h4>
        </div>

        <p className="ms-garcia-card__message">
          You covered Othello's motives, but missed Iago’s tricks in Act 3. Focus more on that!
        </p>
      </CardContent>
      </Card>
 <Card
  style={{
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2), 0px -0px 2px rgba(0, 0, 0, 0.2)',     maxWidth: '300px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
   marginLeft: 'auto'
  }}
>
  <Avatar sx={{ bgcolor: '#5f9ea0', width: 28, height: 28 }}>Ma</Avatar>
  <img src={recording} alt="Upload Scripts for Marking" className="recording-image" />
</Card>

        </div>
          <div className="features-section__card">
              <h4 className="">Understand and support all learners.</h4>
              <p>Gain clear insights into student progress for timely support and improved outcomes.</p>
      <img src={metrics} alt="Upload Scripts for Marking" className="metrics" />
      </div>
      </div>
    </div>
  );
};

export default Features;

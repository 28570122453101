import React, { useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { Box, Card, CardContent, FormControlLabel, Switch } from "@mui/material";

const MarksGraph = ({ data }) => {
  const [chartType, setChartType] = useState('line');

  const handleToggleChartType = () => {
    setChartType(chartType === 'line' ? 'bar' : 'line');
  };

  const colors = {
    line: '#5F9EA0',
    bar: '#80E0B1'
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
          <p className="label" style={{ fontWeight: 'bold', marginBottom: '5px' }}>{`${label}`}</p>
          <p className="intro" style={{ color: colors.bar, fontWeight: 'bold', marginBottom: '5px' }}>{`Percentage: ${payload[0].value !== null ? payload[0].value + '%' : 'N/A'}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card style={{ margin: '10px 20px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={chartType === 'bar'}
                onChange={handleToggleChartType}
                name="chartType"
                color="primary"
              />
            }
            label={chartType === 'line' ? 'Switch to Bar Chart' : 'Switch to Line Chart'}
          />
        </Box>
        {chartType === 'line' ? (
          <LineChart width={1000} height={320} data={data} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="percentage" stroke={colors.line} />
          </LineChart>
        ) : (
          <BarChart width={1000} height={320} data={data} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="percentage" fill={colors.bar} />
          </BarChart>
        )}
      </CardContent>
    </Card>
  );
};

export default MarksGraph;

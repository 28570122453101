import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import AssessmentApiService from "../../services/api/assessmentAPIService";
import "./ReviewAIMarking.css";
import Box from "@mui/material/Box";

// Status mapping and color coding with lighter colors
const statusMapping = {
    'Test Ready': { name: 'Test Ready', color: '#f8d7da', textColor: '#5F9EA0' }, // pink background, teal text
    'Ready for Review': { name: 'Ready for Review', color: '#fff7d0', textColor: '#eda101' }, // orange
    'Complete Review': { name: 'Complete Review', color: '#c6e7ee', textColor: '#07919b' }, // teal
    'In Review': { name: 'In Review', color: '#ffd9f7', textColor: '#f317dd' }, // fuchsia
    'Marking Pending': { name: 'Marking Pending', color: '#d0f0c0', textColor: '#388e3c' } // green
};

const ReviewAIMarking = () => {
    const { assessmentId } = useParams();
    const [tests, setTests] = useState([]);
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const navigate = useNavigate();

    const { token, user_role } = parsedUserInfo;

    const fetchTests = useCallback(() => {
        AssessmentApiService.fetchOnlineTestsByExamId(assessmentId, token)
            .then(data => {
                if (data) {
                    const mappedTests = data.map(test => ({
                        ...test,
                        status_name: statusMapping[test.status]?.name,
                        status_color: statusMapping[test.status]?.color,
                        status_textColor: statusMapping[test.status]?.textColor,
                    }));
                    setTests(mappedTests);
                } else {
                    console.error("Failed to fetch tests");
                }
            })
            .catch(error => {
                console.error("Error fetching tests:", error);
            });
    }, [assessmentId, token]);

    useEffect(() => {
        fetchTests();
    }, [fetchTests]);

    const handleNavigateToFeedback = (onlineTestId, name) => {
        navigate(`/feedback/${onlineTestId}/${name}`);
    };

    const handleDeleteTest = async (onlineTestId) => {
        const confirmed = window.confirm("Are you sure you want to delete this test?");
        if (confirmed) {
            try {
                const response = await AssessmentApiService.deleteOnlineTest(onlineTestId, token);
                if (response) {
                    fetchTests();
                } else {
                    console.error("Failed to delete the test");
                }
            } catch (error) {
                console.error("Error deleting the test:", error);
            }
        }
    };

    const columns = [
        { field: 'student_name', headerName: 'Student Name', width: 250 },
        {
            field: 'status_name', headerName: 'Status', width: 200, renderCell: (params) => (
                <div style={{
                    backgroundColor: params.row.status_color,
                    width: '80%',
                    height: '60%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                    margin: '1px',
                    color: params.row.status_textColor,
                    textDecoration: 'none'
                }}>
                    <CircleIcon style={{ fontSize: 'xx-small', marginRight: '5px', color: params.row.status_textColor }} />
                    {params.row.status_name}
                </div>
            )
        },
        { field: 'exam_name', headerName: 'Assessment Name', width: 200 },
        { field: 'calculated_duration', headerName: 'Duration (Minutes)', width: 180 },
        {
            field: 'marking',
            headerName: 'Marking',
            width: 150,
            align: 'left',
            headerClassName: 'centered-header',
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={user_role === 'teacher' && params.row.status_name === 'Complete Review' ? 'Edit Feedback' : 'View Feedback'}>
                        <IconButton
                            color="primary"
                            onClick={() => handleNavigateToFeedback(params.row.id, params.row.student_name)}
                            style={{ marginRight: 8 }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    {user_role === 'admin' && (
                        <Tooltip title="Delete Test">
                            <IconButton
                                color="primary"
                                onClick={() => handleDeleteTest(params.row.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            )
        }
    ];

    const handleNavigateToStats = () => {
        navigate(`/stats/${assessmentId}`);
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Typography
                    className="review-marking-header"
                    sx={{
                        fontFamily: 'Roboto, sans-serif',
                        textAlign: 'left',
                        lineHeight: 1.02,
                        marginBottom: '20px',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: '#5F9EA0'
                    }}
                >
                    Marking
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<VisibilityIcon />}
                    onClick={handleNavigateToStats}
                >
                    View Stats
                </Button>
            </Box>
            <div className="review-marking-table" style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={tests}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    );
};

export default ReviewAIMarking;

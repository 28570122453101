import React, { useEffect } from "react";
import "./AdobePDFViewer.css";

const AdobePDFViewer = ({ pdfUrl }) => {

  useEffect(() => {
    if (!pdfUrl) {
      console.error("No PDF URL provided!");
      return; // Stop execution if no valid URL is passed
    }

    if (window.AdobeDC) {
      const adobeDCView = new window.AdobeDC.View({
        clientId: "252d2b55da9e41238c7ecea89f59eefb",
        divId: "adobe-pdf-viewer",
      });

      console.log("Educase: ", pdfUrl);

      // Embed the PDF file
      var previewFilePromise = adobeDCView.previewFile({
        content: {
          location: {
            url: pdfUrl, // Check that this URL points to a valid PDF
          },
        },
        metaData: {
          fileName: "exam.pdf",
          id: "77c6fa5d-6d74-4104-8349-657c8411a834"
        },
      }, {
        embedMode: "FULL_WINDOW",
        enableAnnotationAPIs: true,
        includePDFAnnotations: true,
        downloadWithAnnotations: true,
        showAnnotationTools: true,
      });

      previewFilePromise.then(adobeViewer => {
        adobeViewer.getAnnotationManager().then(annotationManager => {
          // Start annotation mode (e.g., shape or freetext) with custom options
          const annotationOptions = {
            defaultColor: "#FF0000",
            cursor: "crosshair",
            strokeWidth: 2
          };

          const annotationMode = "shape";

          // Start the annotation with the given mode and options
          annotationManager.startAnnotationMode(annotationMode, annotationOptions)
            .then(result => {
              console.log("Annotation mode started:", result);
            })
            .catch(error => {
              console.error("Error starting annotation mode:", error);
            });
        });
      })
      .catch(error => {
        console.error("Error loading PDF:", error);
      });
    }
  }, [pdfUrl]);

  return (
    <div id="adobe-pdf-viewer" className="adobePDFViewerContainer"></div>
  );
};

export default AdobePDFViewer;
import React, { useState } from "react";
import { Box, Card, CardContent, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

const QuestionStats = ({ questions }) => {
  const [view, setView] = useState('barChart');

  // Prepare data for the bar chart
  const chartData = questions.map((question, index) => {
    const passCount = question.learners.filter(learner => learner.passed).length;
    const failCount = question.learners.filter(learner => !learner.passed && !learner.skipped).length;
    const skippedCount = question.learners.filter(learner => learner.skipped).length;

    return {
      name: `Question ${index + 1}`,
      Pass: passCount,
      Fail: failCount,
      Skipped: skippedCount,
      learnersPass: question.learners.filter(learner => learner.passed),
      learnersFail: question.learners.filter(learner => !learner.passed && !learner.skipped),
      learnersSkipped: question.learners.filter(learner => learner.skipped),
    };
  });

  const failSortedQuestions = [...chartData].sort((a, b) => b.Fail - a.Fail);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const passCount = payload[0].payload.Pass;
      const failCount = payload[0].payload.Fail;
      const skippedCount = payload[0].payload.Skipped;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
          <p className="label">{`${label}`}</p>
          <p className="intro" style={{ color: '#80E0B1' }}>{`Pass: ${passCount}`}</p>
          <p className="intro" style={{ color: '#F08080' }}>{`Fail: ${failCount}`}</p>
          <p className="intro" style={{ color: '#FFCC99' }}>{`Skipped: ${skippedCount}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Card style={{ margin: '10px 20px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={view === 'horizontalBarChart'}
                onChange={(e) => setView(e.target.checked ? 'horizontalBarChart' : 'barChart')}
                name="viewToggle"
                color="primary"
              />
            }
            label={`Switch to ${view === 'horizontalBarChart' ? 'Pass/Fail Chart' : 'Best to Worst'}`}
          />
        </Box>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          {view === 'barChart' && (
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                This bar chart shows the pass, fail, and skipped rates for each question.
              </Typography>
              <BarChart width={1000} height={300} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="Pass" fill="#5abcb5" />
                <Bar dataKey="Fail" fill="#895bbd" />
                <Bar dataKey="Skipped" fill="#FFCC99" />
              </BarChart>
            </Grid>
          )}
          {view === 'horizontalBarChart' && (
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                This horizontal bar chart shows the performance for each question sorted from best to worst.
              </Typography>
              <BarChart width={1000} height={300} data={failSortedQuestions} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="Pass" fill="#5abcb5" />
                <Bar dataKey="Fail" fill="#895bbd" />
                <Bar dataKey="Skipped" fill="#FFCC99" />
              </BarChart>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuestionStats;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { OnlineTestApiService } from "../../../../services/api/OnlineTestAPIService";
import CourseAssessmentResultsApiService from "../../../../services/api/courseAssessmentResultsApiService";

const ManualMarkingClassList = () => {
  const { examId, courseId, cAId } = useParams(); // cAId is the CourseAssessmentId
  const [learners, setLearners] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLearners, setFilteredLearners] = useState([]);
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const navigate = useNavigate();
  const { token } = parsedUserInfo;

  useEffect(() => {
    const fetchLearners = async () => {
      try {
        const response = await OnlineTestApiService.fetchOnlineTestsByExamId(examId, courseId, token);
        if (response) {
          setLearners(response.learners);
          setFilteredLearners(response.learners); // Initially display all learners
        } else {
          console.error("Failed to fetch learners.");
        }
      } catch (error) {
        console.error("Error fetching learners:", error);
      }
    };

    fetchLearners();
  }, [examId, courseId, token]);

  const createInitials = (firstName, lastName) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = learners.filter((learner) =>
      `${learner.first_name} ${learner.last_name}`.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLearners(filtered);
  };

  const handleRowClick = (params) => {
    const onlineTestId = params.row.online_test_id;
    navigate(`/manual-marking/marking/${onlineTestId}/${examId}/${cAId}`);
  };

  const handleDoneMarking = async () => {
    try {
      const response = await CourseAssessmentResultsApiService.updateAssessmentStats(cAId, token);
      if (response) {
        console.log("Assessment stats updated successfully:", response);
        // You can navigate or show success message here
      }
    } catch (error) {
      console.error("Failed to update assessment stats:", error);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderCell: (params) => (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{
                width: 32,
                height: 32,
                marginRight: 1,
                backgroundColor: "#5f9ea0",
                color: "white",
              }}
            >
              {createInitials(params.row.first_name, params.row.last_name)}
            </Avatar>
            <span>{`${params.row.first_name} ${params.row.last_name}`}</span>
          </Box>
          {params.row.attempted}
        </Box>
      ),
    },
    {
      field: "attempted",
      headerName: "Attempted",
      flex: 1,
      renderCell: (params) => (
        <Box>
          {params.row.attempted ? "Yes" : "No"} {/* Indicate if test was attempted */}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Test Status",
      flex: 1,
      renderCell: (params) => <Box>{params.row.status}</Box>,
    },
  ];

  return (
    <div style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "5px" }}>
      <div style={{ margin: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <TextField
          id="search"
          placeholder="Search Learners..."
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          variant="outlined"
          InputProps={{
            style: { borderRadius: 25 },
          }}
          sx={{
            width: { xs: "100%", sm: "300px", md: "400px", lg: "500px" },
            "& .MuiOutlinedInput-root": {
              borderRadius: "25px",
              borderColor: "#d0d4d9",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d0d4d9",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#b3b3b3",
            },
            "& .MuiInputBase-input": {
              padding: "10px",
              color: "#5f9ea0",
            },
          }}
        />
        {/* Done Marking Button */}
        <Button
          variant="contained"
          color="success"
          sx={{ marginLeft: 2, borderRadius: "25px", backgroundColor: "#5f9ea0" }}
          onClick={handleDoneMarking}
        >
          Done Marking
        </Button>
      </div>

      <Box sx={{ overflowY: "hidden", height: "auto", padding: { xs: 0, sm: 0, md: 1, lg: 2, xl: 2 } }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            backgroundColor: "white",
            borderRadius: "16px",
            border: "2px solid #cfd8dc",
            boxShadow: "none",
            width: "95%",
            minHeight: "80vh",
          }}
        >
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredLearners.map((learner) => ({ ...learner, id: learner.id }))}
              columns={columns}
              pageSize={10}
              rowHeight={60}
              headerHeight={56}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              onRowClick={handleRowClick}
              autoHeight
              getRowClassName={(params) => (params.row.attempted ? "" : "grey-out-row")}
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                border: "none",
                "& .grey-out-row": {
                  backgroundColor: "#f0f0f0", // Grey out the row
                  color: "#b0b0b0", // Grey out the text
                },
              }}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ManualMarkingClassList;

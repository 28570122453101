import { useEffect, useState } from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSubjectDetails } from "../../reducers/assessment";
import SubjectApiService from "../../services/api/subjectAPIService";

const userInfo = localStorage.getItem("user");
const parsedUserInfo = JSON.parse(userInfo || "{}");

const { token, user_role } = parsedUserInfo;

const AssessmentDetailsStep = ({ handleStepOneChange, errors, handleNextStep }) => {
    const assessmentState = useSelector(state => state.assessment);
    const dispatch = useDispatch();
    const [subjects, setSubjects] = useState([]);
    const [, setGrades] = useState([]);
    const [filteredGrades, setFilteredGrades] = useState([]);
    const [filteredSubjects, setFilteredSubjects] = useState([]);
    const [isFormComplete, setIsFormComplete] = useState(false); // New state for form completion

    const handleChange = (event) => {
        const { name, value } = event.target;
        handleStepOneChange(event);

        if (name === "subject") {
            const selectedSubject = subjects.find(subject => subject.name === value);

            if (selectedSubject) {
                dispatch(updateSubjectDetails({ id: selectedSubject.id, name: selectedSubject.name }));
                const subjectGrades = selectedSubject.grades.map(grade => grade.grade);
                setFilteredGrades(subjectGrades);
            } else {
                setFilteredGrades([]);
            }
        }
    };

    const [examTypeOptions, setExamTypeOptions] = useState([
        { value: "practice_test", label: "Practice Test" },
        { value: "exam", label: "Exam" },
        { value: "test", label: "Test" },
        { value: "baseline_test", label: "Baseline Test" }
    ]);

    const fetchSubjects = async () => {
        const response = await SubjectApiService.fetchSubjects();
        if (response.ok) {
            const subjects = await response.json();
            setSubjects(subjects);

            // Extract all unique grades from the subjects
            const allGrades = [...new Set(subjects.flatMap(subject => subject.grades.map(grade => grade.grade)))];
            setGrades(allGrades);
            setFilteredGrades(allGrades);
            setFilteredSubjects(subjects);
        }
    };

    useEffect(() => {
        fetchSubjects();
    }, []);

    // Set up exam types based on user role
    useEffect(() => {
        if (user_role === 'admin') {
            setExamTypeOptions(prevOptions => [
                ...prevOptions,
                { value: "sample_test", label: "Sample Test" }
            ]);
        }
    }, []);

    // Check if the form is complete
    useEffect(() => {
        const isComplete = assessmentState.name && assessmentState.subject_name && assessmentState.grade && assessmentState.type;
        setIsFormComplete(isComplete);
    }, [assessmentState.name, assessmentState.subject_name, assessmentState.grade, assessmentState.type]);

    return (
        <Card sx={{
            border: "1px solid #5f9ea0",
            borderRadius: 2,
            padding: 3,
            marginLeft: { xs: 2, sm: 3, md: 5 },
            marginRight: { xs: 2, sm: 3, md: 5 },
            marginTop: 2,
            width: { xs: 'auto', sm: 'auto', md: '500px' },
            minHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
        }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography
                        variant="body1"
                        sx={{ mb: 2, mt: 0, color: '#777f86', textAlign: 'left', fontSize: '1rem' }}
                    >
                        Quickly create your question paper through our drag and drop interface.
                    </Typography>

                    <Typography variant="h6" sx={{ mb: 2, mt: 0, color: '#777f86', textAlign: 'left', fontSize: '1rem', fontWeight: 'bold' }}>
                        What are the assessment details?
                    </Typography>

                    {errors && Object.keys(errors).length > 0 && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            <AlertTitle>Error</AlertTitle>
                            {Object.values(errors).map((error, index) => <li key={index}>{error}</li>)}
                        </Alert>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Assessment Name<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            name="name"
                            value={assessmentState.name}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder="Type the assessment name"
                            sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Subject<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                            <Select
                                name="subject"
                                value={assessmentState.subject_name || ''}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ height: '35px' }}
                                renderValue={(selected) => {
                                    if (selected === '') {
                                        return <span style={{ color: '#aaa' }}>Select a subject</span>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select a subject, e.g., Mathematics
                                </MenuItem>
                                {filteredSubjects.map((subject, index) => (
                                    <MenuItem key={index} value={subject.name}>{subject.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Grade<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <FormControl fullWidth variant="outlined" margin="dense" disabled={!assessmentState.subject} sx={{ height: '35px' }}>
                            <Select
                                name="grade"
                                value={assessmentState.grade || ''}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ height: '35px', color: !assessmentState.subject ? 'grey' : 'inherit' }}
                                renderValue={(selected) => {
                                    if (selected === '') {
                                        return <span style={{ color: '#aaa' }}>Select a grade</span>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select a grade, e.g., Grade 8
                                </MenuItem>
                                {filteredGrades.map((grade, index) => (
                                    <MenuItem key={index} value={grade}>{grade}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Assessment Type<span style={{ color: 'red' }}>*</span>
                        </Typography>
                            <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                <Select
                                    name="type"
                                    value={assessmentState.type || ''}
                                    onChange={handleChange}
                                    displayEmpty
                                    sx={{ height: '35px' }}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span style={{ color: '#aaa' }}>Select an assessment type</span>;
                                        }
                                        // Find the corresponding label from examTypeOptions
                                        const selectedOption = examTypeOptions.find(option => option.value === selected);
                                        return selectedOption ? selectedOption.label : selected;
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an assessment type
                                    </MenuItem>
                                    {examTypeOptions.map((type, index) => (
                                        <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                            Date of Assessment
                        </Typography>
                        <TextField
                            type="date"
                            name="assessment_date"
                            value={assessmentState.assessment_date}
                            onChange={handleChange}
                            required
                            fullWidth
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Type the date of the assessment"
                            variant="outlined"
                            sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Tooltip title="Next you will choose topics" arrow>
                            <span>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: 'primary', color: 'white', width: '150px', fontSize: '12px', borderRadius: '20px' }}
                                    onClick={() => {
                                        handleNextStep();
                                    }}
                                    disabled={!isFormComplete} // Disable button if form is incomplete
                                >
                                    Choose Topics
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default AssessmentDetailsStep;

import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import SummaryCard from "./SummaryCard/SummaryCards";
import TopLowestMark from "./TopLowestMark/TopLowestMark";
import MarksGraph from "./MarksGraph.py/MarksGraph";
import TopicsStats from "./TopicsStats/TopicsStats";
import QuestionStats from "./QuestionStats/QuestionStats";
import MarksTable from "./MarksTable/MarksTable";
import nodataavaiable from "../../assets/nodataavaiable.webp";
import { useParams } from "react-router-dom";
import DisplayContent from "../../components/displayContent/displayContent";

// Mock data generator
const generateMockData = () => {
  const studentNames = [
    "P Tshabalala", "G Claassen", "D MacGall", "A Mokoena", "K Ndaba",
    "B Nkosi", "T Sithole", "N Mdluli", "S Khumalo", "L Mokoena",
    "M Zulu", "R Dlamini", "X Mthethwa", "Y Mlangeni", "Z Ntuli",
    "H Mthembu", "J Dube", "K Ndlovu", "L Masilela", "M Ngwenya",
    "N Mkhize", "O Zwane", "P Buthelezi", "Q Hadebe", "R Gwala",
    "S Shongwe", "T Msibi", "U Sibisi", "V Maseko", "W Hlatshwayo",
    "X Zondo", "Y Nkabinde", "C Mngomezulu"
  ];

  const topics = [
    { id: 437, name: "WHOLE NUMBERS" },
    { id: 445, name: "GEOMETRY OF 2D SHAPES" },
    { id: 637, name: "DATA HANDLING" },
    { id: 512, name: "COMMON FRACTIONS" },
    { id: 521, name: "DECIMAL FRACTIONS" }
  ];

  const getRandomMark = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const generateLearnersData = (topicId) => {
    return studentNames.map(name => {
      const mark = getRandomMark(0, 100);
      const total_marks = 100;
      const percentage = (mark / total_marks) * 100;
      const passed = percentage >= 40;
      const failed = !passed;
      const skipped = mark === 0;

      return {
        name,
        mark,
        total_marks,
        percentage,
        passed,
        failed,
        skipped
      };
    });
  };

  const graphData = studentNames.map(name => {
    const mark = getRandomMark(3, 100); // 3% to 100% to simulate a failing case
    return {
      name,
      mark,
      percentage: (mark / 100) * 100
    };
  });

  const topicsData = topics.map(topic => ({
    id: topic.id,
    name: topic.name,
    pass: graphData.filter(student => student.percentage >= 40).length,
    fail: graphData.filter(student => student.percentage < 40).length,
    average: graphData.reduce((sum, student) => sum + student.percentage, 0) / studentNames.length,
    topMark: Math.max(...graphData.map(student => student.percentage)),
    lowMark: Math.min(...graphData.map(student => student.percentage)),
    learners: generateLearnersData(topic.id)
  }));

  const questionsData = topics.flatMap(topic => {
    const numberOfQuestions = getRandomMark(3, 5);
    return Array.from({ length: numberOfQuestions }, (_, index) => ({
      pass: graphData.filter(student => student.percentage >= 40).length,
      fail: graphData.filter(student => student.percentage < 40).length,
      average: graphData.reduce((sum, student) => sum + student.percentage, 0) / studentNames.length,
      topMark: Math.max(...graphData.map(student => student.percentage)),
      lowMark: Math.min(...graphData.map(student => student.percentage)),
      topic_id: topic.id,
      learners: generateLearnersData(topic.id)
    }));
  });

  return {
    name: "Baseline Test Grade 7",
    pass: (graphData.filter(student => student.percentage >= 40).length / studentNames.length) * 100,
    fail: (graphData.filter(student => student.percentage < 40).length / studentNames.length) * 100,
    average: graphData.reduce((sum, student) => sum + student.percentage, 0) / studentNames.length,
    highest: {
      mark: Math.max(...graphData.map(student => student.mark)),
      percentage: Math.max(...graphData.map(student => student.percentage)),
      name: graphData.reduce((prev, current) => (prev.mark > current.mark) ? prev : current).name
    },
    lowest: {
      mark: Math.min(...graphData.map(student => student.mark)),
      percentage: Math.min(...graphData.map(student => student.percentage)),
      name: graphData.reduce((prev, current) => (prev.mark < current.mark) ? prev : current).name
    },
    graphData,
    topics: topicsData,
    questions: questionsData
  };
};


const StatsPage = () => {
  const { assessmentId } = useParams();
  const [view, setView] = useState('graph');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aiAnalysisOpen, setAiAnalysisOpen] = useState(false);

  useEffect(() => {
    const fetchedData = generateMockData();
    setData(fetchedData);
    setLoading(false);
  }, [assessmentId]);

  const handleAiAnalysisOpen = () => {
    setAiAnalysisOpen(true);
  };

  const handleAiAnalysisClose = () => {
    setAiAnalysisOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
          <Typography variant="h6" style={{ color: '#5F9EA0', marginBottom: '20px' }}>No Data Available</Typography>
          <img src={nodataavaiable} alt="No Data Available" style={{ width: '300px', height: '300px' }} />
        </Box>
      </Container>
    );
  }

  const { name, pass, fail, average, highest, lowest, graphData, topics, questions } = data;

  const numPass = graphData.filter(student => student.percentage >= 40).length;
  const numFail = graphData.filter(student => student.percentage < 40).length;
  const totalStudents = graphData.length;
  const examName = name;

  // Calculate best and worst performing topics
  const bestTopic = topics.reduce((prev, current) => (prev.pass > current.pass) ? prev : current);
  const worstTopic = topics.reduce((prev, current) => (prev.pass < current.pass) ? prev : current);

  const aiAnalysis = `
    <p><h1>AI Analysis Report</h1></p>

<ol>
  <li><h1>Overall Performance:</h1><br>
    The overall class performance in the Baseline Test for Grade 7 shows a mixed set of results. While a few students performed exceptionally well, achieving marks above 80%, a significant portion of the class displayed moderate to low performance, with several students barely passing or even failing the test. This suggests a varied level of understanding of the topics covered, indicating that while some students have grasped the concepts well, others may be struggling.
  </li>

  <br>

  <li><h1>Top Performing Topics:</h1><br>
    The topic of WHOLE NUMBERS appears to be the strongest area for the class, with a high pass rate and a solid average score. Students generally demonstrated a good understanding of basic arithmetic operations and number concepts. This could indicate that the foundational numeracy skills are well-developed among the students, which is encouraging as these skills are crucial for more advanced mathematical topics.
  </li>

  <br>

  <li><h1>Areas of Concern:</h1><br>
    Conversely, the topic of GEOMETRY OF 2D SHAPES seems to be a challenging area for many students. The pass rate for this topic is notably lower compared to other topics, and the average scores indicate that students are struggling with understanding shapes, angles, and spatial relationships. The errors made by students suggest a lack of comprehension of the properties of geometric figures, which may stem from insufficient exposure to practical geometry exercises or difficulty in visualizing two-dimensional shapes.
  </li>

  <br>

  <li><h1>Detailed Topic Insights:</h1>
    <ul>
      <li><h1>WHOLE NUMBERS:</h1> Most students excelled in this topic, with marks typically ranging from 60% to 100%. The few students who did not perform as well might benefit from additional practice with more complex number operations or multi-step arithmetic problems to build confidence and accuracy.</li>

      <br>

      <li><h1>COMMON FRACTIONS:</h1> Performance in this area was average, with many students showing a reasonable understanding of fraction concepts. However, there were common mistakes observed in operations involving mixed numbers and improper fractions, indicating a need for targeted revision sessions focusing on these specific subtopics.</li>

      <br>

      <li><h1>DECIMAL FRACTIONS:</h1> This topic saw mixed results. While some students showed proficiency in converting fractions to decimals and vice versa, others struggled, especially with operations involving decimals. Misalignment in place value understanding was a recurring issue, which could be addressed through visual aids and interactive decimal exercises.</li>

      <br>

      <li><h1>GEOMETRY OF 2D SHAPES:</h1> As mentioned earlier, this topic was particularly challenging for the class. Many students struggled with identifying and calculating the properties of shapes such as triangles and quadrilaterals. There is a clear need for more hands-on activities, possibly using physical models or drawing exercises to enhance spatial reasoning and understanding of geometric principles.</li>

      <br>

      <li><h1>DATA HANDLING:</h1> Students generally performed well in this area, showing a good understanding of data collection, representation, and interpretation. However, a few students had difficulty with more complex data sets and drawing inferences from graphs. This can be improved by incorporating more real-life data handling scenarios into the lessons.</li>
    </ul>
  </li>

  <br>

  <li><h1>Student Engagement and Attention:</h1><br>
    Several students skipped questions, particularly in topics like <GEOMETRY OF 2D SHAPES and DECIMAL FRACTIONS. This may indicate either a lack of understanding or a lack of confidence in attempting these questions. It could also suggest that these students require more support during class discussions and might benefit from one-on-one tutoring sessions to build their confidence in these areas.
  </li>

  <br>

  <li><h1>Recommendations:</h1><br>
    <ul>
      <li><h1>Focused Revision:</h1> Emphasis should be placed on revising GEOMETRY OF 2D SHAPES and DECIMAL FRACTIONS through interactive lessons that involve practical application and visual learning techniques.</li>

      <br>

      <li><h1>Peer Learning:</h1> Encourage students who performed well in WHOLE NUMBERS and DATA HANDLING to support their peers, perhaps through peer-teaching sessions, to foster a collaborative learning environment.</li>

      <br>

      <li><h1>Differentiated Instruction:</h1> Given the wide range of performance, consider implementing differentiated instruction strategies to cater to the varied learning needs of the students. For instance, providing additional challenges for higher-performing students while offering more guided practice for those who are struggling.</li>
    </ul>
  </li>

  <br>

  <li><h1>Next Steps:</h1><br>
    The next set of lessons should prioritize strengthening the understanding of geometric concepts, ensuring that all students have the opportunity to solidify their knowledge before moving on to more advanced topics. Regular formative assessments should be conducted to monitor progress and adjust teaching strategies accordingly.
  </li>
</ol>

  `;

  return (
    <Container>
      <Box style={{ maxHeight: '800px', overflowY: 'scroll' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="review-marking-header"
              sx={{
                fontFamily: 'Roboto, sans-serif',
                textAlign: 'left',
                lineHeight: 1.02,
                marginBottom: '20px',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#5F9EA0'
              }}
            >
              Result Analysis for Assessment: {examName}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleAiAnalysisOpen} style={{ float: 'right' }}>
              AI Analysis
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Box flex={2} mr={2}>
                <SummaryCard
                  pass={pass}
                  fail={fail}
                  average={average}
                  numPass={numPass}
                  numFail={numFail}
                  totalStudents={totalStudents}
                  bestTopic={{ name: bestTopic.name, percentage: bestTopic.pass }}
                  worstTopic={{ name: worstTopic.name, percentage: worstTopic.pass }}
                />
              </Box>
              <Box flex={1}>
                <TopLowestMark highest={highest} lowest={lowest} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
            <Button variant="contained" onClick={() => setView('graph')} style={{ marginRight: 10 }}>
              Learner Performance Graph
            </Button>
            <Button variant="contained" onClick={() => setView('table')} style={{ marginRight: 10 }}>
              Learner Performance Table
            </Button>
            <Button variant="contained" onClick={() => setView('topics')} style={{ marginRight: 10 }}>
              Class Topic Analysis
            </Button>
            <Button variant="contained" onClick={() => setView('questions')}>
              Class Question Analysis
            </Button>
          </Grid>
          <Grid item xs={12}>
            {view === 'graph' && (
              <>
                <Typography variant="subtitle1" gutterBottom>Learner Performance Graph</Typography>
                <Typography variant="body2" gutterBottom>
                  This graph shows the distribution of marks for each learner.
                </Typography>
                <MarksGraph data={graphData} />
              </>
            )}
            {view === 'table' && (
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>Learner Performance Table</Typography>
                  <Typography variant="body2" gutterBottom>
                    This table provides a detailed breakdown of each learner's performance, including their overall mark, the topic they performed best in, the topic they struggled the most with, and the number of questions they skipped.
                  </Typography>
                  <MarksTable data={graphData} topics={topics} questions={questions} />
                </CardContent>
              </Card>
            )}
            {view === 'topics' && (
              <>
                <Typography variant="subtitle1" gutterBottom>Class Topic Analysis</Typography>
                <Typography variant="body2" gutterBottom>
                  This section provides a detailed analysis of learners' performance across different topics.
                </Typography>
                <TopicsStats topics={topics} />
              </>
            )}
            {view === 'questions' && (
              <>
                <Typography variant="subtitle1" gutterBottom>Class Question Analysis</Typography>
                <Typography variant="body2" gutterBottom>
                  This section provides a detailed analysis of learners' performance on different questions.
                </Typography>
                <QuestionStats questions={questions} />
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Dialog open={aiAnalysisOpen} onClose={handleAiAnalysisClose} maxWidth="md" fullWidth>
        <DialogTitle>AI Analysis Report</DialogTitle>
        <DialogContent dividers>
          <DisplayContent content={aiAnalysis} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAiAnalysisClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StatsPage;

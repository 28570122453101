import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import getConfig from "../../../../config/index";
import "./ImageUploader.css"; // Import the CSS

const ImageUploadComponent = ({ subject, grade, assessmentId, learnerId, onImageUpload, onImageDelete, existingImages }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeUploads, setActiveUploads] = useState(0);

    useEffect(() => {
        const initializedFiles = existingImages.map((url, index) => ({
            file: { name: `ExistingImage_${index}` },
            name: `ExistingImage_${index}`,
            url
        }));
        setFiles(initializedFiles);
    }, [existingImages]);

    const onDrop = useCallback(async (acceptedFiles) => {
        setLoading(true);
        setActiveUploads(acceptedFiles.length);
        const config = getConfig();

        for (const file of acceptedFiles) {
            const isExisting = existingImages.some(existing => existing.file === file);

            if (!isExisting) {
                const hashedFileName = `educase_learners_answer_${generateRandomHash()}_${subject}_${grade}.png`;

                try {
                    const formData = new FormData();
                    formData.append("document", file, hashedFileName);
                    formData.append("userId", learnerId);
                    formData.append("assessmentId", assessmentId);
                    formData.append("environment", config.api.base_url);
                    formData.append("pdfConversion", "pending");

                    const response = await axios.post(`https://scripts.educasetools.workers.dev/upload-pdf`, formData, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(prev => ({
                                ...prev,
                                [hashedFileName]: {
                                    state: "pending",
                                    percentage: percentCompleted
                                }
                            }));
                        },
                        headers: {
                            'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137',
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const fileUrl = response.data.url;
                    onImageUpload(fileUrl);

                    setUploadProgress(prev => ({
                        ...prev,
                        [hashedFileName]: {
                            state: "done",
                            percentage: 100,
                            url: fileUrl
                        }
                    }));

                    setFiles(prevFiles => [...prevFiles, { file: file, name: file.name, url: fileUrl }]);
                } catch (error) {
                    setUploadProgress(prevState => ({
                        ...prevState,
                        [hashedFileName]: {
                            state: "error",
                            percentage: 0
                        }
                    }));
                } finally {
                    setActiveUploads(prev => prev - 1);
                }
            }
        }
    }, [subject, grade, learnerId, assessmentId, onImageUpload, existingImages]);

    useEffect(() => {
        if (activeUploads === 0) {
            setLoading(false);
        }
    }, [activeUploads]);

    const handleDeleteAnswer = useCallback((fileName, event) => {
        event.stopPropagation();
        const fileUrl = uploadProgress[fileName]?.url || files.find(file => file.name === fileName)?.url;
        axios.delete(`https://scripts.educasetools.workers.dev/${encodeURIComponent(fileName)}`, {
            headers: {
                'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137'
            }
        })
            .then(() => {
                if (fileUrl) {
                    onImageDelete(fileUrl);
                }
                setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
            }).catch(error => {
                console.error('Error deleting file:', error);
            });
    }, [uploadProgress, files, onImageDelete]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png',
        maxSize: 5000000 // 5MB max size
    });

    return (
        <div className="answer-image-uploader-container" {...getRootProps()}>
            {loading && (
                <div className="loading-overlay">
                    <CircularProgress className='loading-circular-progress' />
                </div>
            )}
            <div className={`filesContainer ${loading ? 'loading' : ''}`}>
                {files.map((fileData, index) => (
                    <div key={index} className="fileStatus">
                        <p>Image {index + 1}</p>
                        {(uploadProgress[fileData.name]?.state === 'done' || fileData.url) && (
                            <>
                                <img src={fileData.url} className="selected-image-uploader"/>
                            </>
                        )}
                        <IconButton
                            onClick={(e) => handleDeleteAnswer(fileData.name, e)}
                            className="iconButton"
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
            <input {...getInputProps()} />
            <div className={`uploadArea ${loading ? 'loading' : 'upload-image-area'}`}>
                {isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Click to upload...</p>
                }
            </div>
        </div>
    );
};

export default ImageUploadComponent;

function generateRandomHash() {
    const arr = new Uint8Array(8);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('').substring(0, 16);
}
import getConfig from "../../config/index";

export const OnlineTestApiService = {

    async submitLearnerScripts(courseId, assessmentId, submissions, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/create-test-and-submit-answers/`;

            const response = await fetch(url, {
                method: "POST",
                body:  JSON.stringify( {
                    course_id: courseId,
                    assessment_id: assessmentId,
                    submissions: submissions,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            console.log('Test answers submitted successfully:', response.data);

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to create test and submit answer:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error submitting test answers:', error);
        }
    },

    async fetchOnlineTestsByExamId(examId, courseId, token) {
         try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/fetch-by-exam-course/?exam_id=${examId}&course_id=${courseId}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch online tests for exam id', examId,':', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching online tests:', error);
        }
    },

    async fetchImageUrlsByOnlineTestId(online_test_id, exam_id, token) {
         try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/fetch_online_answer_urls/?online_test_id=${online_test_id}&exam_id=${exam_id}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch online test answer urls for exam id', online_test_id,':', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching online tests answer urls:', error);
        }
    }
}
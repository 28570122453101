import React, { useEffect, useState } from "react";
import DisplayContent from "../../../../../components/displayContent/displayContent";
import "./Rubric.css";

const Rubric = ({ question, index, onMarkChange, allocatedMarks }) => {

    const [subQuestionMarks, setSubQuestionMarks] = useState(
        question.sub_questions?.map(sub => allocatedMarks[sub.id]?.allocated_marks || 0) || [] // Default to 0
    );
    const [nestedSubQuestionMarks, setNestedSubQuestionMarks] = useState(
        question.sub_questions?.map(sub =>
            sub.nested_sub_questions?.map(nested => allocatedMarks[nested.id]?.allocated_marks || 0) || [] // Default to 0
        ) || []
    );

    useEffect(() => {
        setSubQuestionMarks(
            question.sub_questions?.map(sub => allocatedMarks[sub.id]?.allocated_marks || 0) || [] // Default to 0
        );
        setNestedSubQuestionMarks(
            question.sub_questions?.map(sub =>
                sub.nested_sub_questions?.map(nested => allocatedMarks[nested.id]?.allocated_marks || 0) || [] // Default to 0
            ) || []
        );
    }, [allocatedMarks, question]);

    const handleSubQuestionMarksChange = (event, subIndex) => {
        const inputMarks = parseFloat(event.target.value || 0); // Default to 0 if input is empty
        const maxSubMarks = parseFloat(question.sub_questions[subIndex].marks || 0);

        if (inputMarks <= maxSubMarks) {
            const newMarks = [...subQuestionMarks];
            newMarks[subIndex] = inputMarks;
            setSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(question.sub_questions[subIndex].id, "subquestion", inputMarks);
        } else {
            alert(`Sub-question ${index + 1}.${subIndex + 1} cannot exceed ${maxSubMarks} marks.`);
        }
    };

    const handleNestedSubQuestionMarksChange = (event, subIndex, nestedIndex) => {
        const inputMarks = parseFloat(event.target.value || 0); // Default to 0 if input is empty
        const maxNestedMarks = parseFloat(question.sub_questions[subIndex].nested_sub_questions[nestedIndex].marks || 0);

        if (inputMarks <= maxNestedMarks) {
            const newMarks = [...nestedSubQuestionMarks];
            newMarks[subIndex][nestedIndex] = inputMarks;
            setNestedSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(question.sub_questions[subIndex].nested_sub_questions[nestedIndex].id, "nested_subquestion", inputMarks);
        } else {
            alert(`Nested sub-question ${index + 1}.${subIndex + 1}.${nestedIndex + 1} cannot exceed ${maxNestedMarks} marks.`);
        }
    };

    return (
        <div className="rubric-container">
            <h3 className="rubric-question-headline">QUESTION {index + 1}</h3>
            {question.instructions && (
                <div className="rubric-question-instructions">
                    <DisplayContent content={question.instructions} />
                </div>
            )}

            {question.sub_questions?.map((subQuestion, subQuestionIndex) => (
                <div key={subQuestionIndex} className="rubric-sub-question-container">
                    <label> {index + 1}.{subQuestionIndex + 1}</label>
                    <DisplayContent content={subQuestion.text} />

                    <input
                        type="number"
                        value={allocatedMarks[subQuestion.id]?.allocated_marks || 0} // Allocated marks or 0 if none
                        onChange={(e) => handleSubQuestionMarksChange(e, subQuestionIndex)}
                        className="rubric-marks-input"
                        placeholder={`Max: ${subQuestion.marks}`} // Placeholder for maximum marks
                    />
                    {/* Display allocated/maximum marks */}
                    <span>{`${allocatedMarks[subQuestion.id]?.allocated_marks || 0} / ${subQuestion.marks}`}</span>

                    {subQuestion.nested_sub_questions?.map((nestedSubQuestion, nestedSubQuestionIndex) => (
                        <div key={nestedSubQuestionIndex} className="rubric-nested-question-container">
                            <label> {index + 1}.{subQuestionIndex + 1}.{nestedSubQuestionIndex + 1}</label>
                            <DisplayContent content={nestedSubQuestion.text} />

                            <input
                                type="number"
                                value={allocatedMarks[nestedSubQuestion.id]?.allocated_marks || 0} // Allocated marks or 0 if none
                                onChange={(e) => handleNestedSubQuestionMarksChange(e, subQuestionIndex, nestedSubQuestionIndex)}
                                className="rubric-marks-input"
                                placeholder={`Max: ${nestedSubQuestion.marks}`} // Placeholder for maximum marks
                            />
                            {/* Display allocated/maximum marks */}
                            <span>{`${allocatedMarks[nestedSubQuestion.id]?.allocated_marks || 0} / ${nestedSubQuestion.marks}`}</span>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Rubric;

import React, { useEffect, useState } from "react";
import MarkingMenu from "./MarkingMenu/MarkingMenu";
import { useParams } from "react-router-dom";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import { FeedbackApiService } from "../../../services/api/FeedbackApiService";

const ManualMarking = () => {
    const [pdfUrl, setPdfUrl] = useState("");
    const [questions, setQuestions] = useState([]);
    const { examId, onlineTestId, cAId  } = useParams();
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    const [allocatedMarks, setAllocatedMarks] = useState({});

    // Function to handle mark changes passed down from Rubric
    const handleMarkChange = (id, type, marks) => {
        setAllocatedMarks(prevState => ({
            ...prevState,
            [id]: { type, allocated_marks: marks }
        }));
    };

    const handleSubmit = async () => {
        const feedbackList = Object.keys(allocatedMarks).map(key => ({
            question_id: key, // This could be question, subquestion, or nested subquestion
            type: allocatedMarks[key].type, // question, subquestion, or nested_subquestion
            allocated_marks: allocatedMarks[key].allocated_marks
        }));

        try {
            const response = await FeedbackApiService.submitManualFeedback(onlineTestId, feedbackList, token);
            if (response) {
                console.log("Manual feedback submitted successfully.");
            } else {
                console.error("Failed to submit manual feedback.");
            }
        } catch (error) {
            console.error("Error submitting manual feedback:", error);
        }
    };

    useEffect(() => {
        const fetchImageUrls = async () => {
            try {
                const response = await OnlineTestApiService.fetchImageUrlsByOnlineTestId(onlineTestId, examId, token);
                if (response) {
                    setQuestions(response.questions);
                    setPdfUrl(response.pdf_url);

                    // Initialize allocated marks and set them to 0 by default
                    const initialMarks = {};
                    response.questions.forEach(question => {
                        // Add question as an independent entry
                        initialMarks[question.id] = { type: "question", allocated_marks: 0 };

                        // Add subquestions as independent entries
                        question.sub_questions?.forEach(subQuestion => {
                            initialMarks[subQuestion.id] = { type: "subquestion", allocated_marks: 0 };

                            // Add nested subquestions as independent entries
                            subQuestion.nested_sub_questions?.forEach(nestedSubQuestion => {
                                initialMarks[nestedSubQuestion.id] = {
                                    type: "nested_subquestion",
                                    allocated_marks: 0
                                };
                            });
                        });
                    });

                    setAllocatedMarks(initialMarks);
                } else {
                    console.error("Failed to fetch image URLs.");
                }
            } catch (error) {
                console.error("Error fetching image URLs:", error);
            }
        };

        fetchImageUrls();
    }, [examId, onlineTestId, token]);

    return (
        <div className="manual-marking-container">
            <div className="top-bar">
                <button className="submit-button" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
            <div className="marking-content">
                <MarkingMenu
                    questions={questions}
                    pdfUrl={pdfUrl}
                    onMarkChange={handleMarkChange} // Pass the mark change handler to MarkingMenu
                    allocatedMarks={allocatedMarks} // Pass the allocated marks to MarkingMenu
                />
            </div>
        </div>
    );
};

export default ManualMarking;

import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./ScriptDetails.css";
import CourseApiService from "../../../services/api/courseAPIService";
import AssessmentApiService from "../../../services/api/assessmentAPIService";

const ScriptDetails = ({ handleFileUpload, selectedFile }) => {
  const [selectedAssessmentId, setSelectedAssessmentId] = useState('');
  const [instructions, setInstructions] = useState('');
  const [markingObjectives, setMarkingObjectives] = useState('');
  const [courses, setCourses] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [passPercentage, setPassPercentage] = useState(''); // New state for pass percentage
  const [resultsDueDate, setResultsDueDate] = useState(''); // New state for due date
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await CourseApiService.getCoursesForTeacher(token);
        if (response.status === 200) {
          const coursesData = await response.json();
          setCourses(coursesData);
        } else {
          console.error("Failed to fetch courses.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [token]);

  const fetchExamsForCourse = async (courseId) => {
    try {
      const response = await AssessmentApiService.fetchExamsForCourse(courseId, token);
      if (response) {
        setAssessments(response);
      } else {
        console.error("Failed to fetch assessments. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  const handleUploadScripts = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Navigate to the UploadScripts component with the selected course ID and additional parameters
    navigate(`/upload-scripts/learners/${selectedCourse.id}/${selectedCourse.subject}/${selectedCourse.grade}/${selectedAssessmentId}`, {
      state: {
        passPercentage,   // Pass Percentage
        resultsDueDate,   // Results Due Date
        instructions,
        markingObjectives
      }
    });
  };

  const handleCourseChange = (e) => {
    const selectedCourse = e.target.value;
    setSelectedCourse(selectedCourse);
    setSelectedAssessmentId(''); // Reset the assessment selection when course changes
    fetchExamsForCourse(selectedCourse.id);
  };

  // Disable the button if course or assessment is not selected
  const isButtonDisabled = !(selectedCourse && selectedAssessmentId);

  return (
    <Card
      sx={{
        border: "1px solid #5f9ea0",
        borderRadius: 2,
        boxShadow: 'none',
        mt: 2,
        mx: 'auto',
        padding: { xs: '0', sm: '10px', md: '15px' },
        width: { xs: '90%', sm: '85%', md: '90%' },
        height: { xs: 'fit-content' , sm: '85%', md: '80%' },  // Ensure width shrinks on smaller screens
        maxWidth: '1200px',
      }}
    >
      <CardContent>
        <form onSubmit={handleUploadScripts}> {/* Wrap the content in a form */}
          <div>
            <p className="marking-project-title">Start a new marking project</p>
            <p className="instructions-text">
              If you'd like a tutorial of this feature, please see the
              <a href="your-video-link-here" target="_blank" rel="noopener noreferrer" className="instructions-link">
                How-to video
              </a>.
            </p>

            {/* 1. Choose Course */}
            <Box mt={2}>
              <p className="section-title">1. Choose Course</p>
              <TextField
                select
                fullWidth
                onChange={handleCourseChange}
                variant="outlined"
                placeholder="Select a course"
                value={selectedCourse}  // Bind to selectedCourse state
                sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course} style={{ color: '#323e48' }}>
                    {course.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* 2. Choose Assessment Paper */}
            <Box mt={2}>
              <p className="section-title">2. Choose Assessment Paper</p>
              <TextField
                select
                fullWidth
                value={selectedAssessmentId}
                onChange={(e) => setSelectedAssessmentId(e.target.value)} // Update the selected exam ID
                variant="outlined"
                placeholder="Select an assessment paper"
                sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
              >
                {assessments.map((assessment) => (
                  <MenuItem key={assessment.id} value={assessment.id} style={{ color: '#323e48' }}>
                    {assessment.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* 3. Marking Objectives Input */}
            <Box mt={2}>
              <p className="section-title">3. Marking Objectives</p>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={markingObjectives}
                onChange={(e) => setMarkingObjectives(e.target.value)}
                variant="outlined"
                placeholder="Type the marking objectives here"
              />
            </Box>

            {/* 4. Instructions Input */}
            <Box mt={2}>
              <p className="section-title">4. Instructions</p>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                variant="outlined"
                placeholder="Type the instructions for marking"
              />
            </Box>

            {/*/!* 5. Pass Percentage *!/*/}
            {/*<Box mt={2}>*/}
            {/*  <p className="section-title">5. Pass Percentage</p>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="number"*/}
            {/*    value={passPercentage}*/}
            {/*    onChange={(e) => setPassPercentage(e.target.value)}*/}
            {/*    variant="outlined"*/}
            {/*    placeholder="Enter pass percentage"*/}
            {/*  />*/}
            {/*</Box>*/}

            {/*/!* 6. Results Due Date *!/*/}
            {/*<Box mt={2}>*/}
            {/*  <p className="section-title">6. Results Due Date</p>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="date"*/}
            {/*    value={resultsDueDate}*/}
            {/*    onChange={(e) => setResultsDueDate(e.target.value)}*/}
            {/*    variant="outlined"*/}
            {/*    placeholder="Select the due date for results"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,  // Ensure that the label remains above the field when a value is entered*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Box>*/}

            {/* Button at the bottom right inside the card */}
            <Box className="button-section" mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"  // Form submission via button
                disabled={isButtonDisabled}  // Disable if course or assessment not selected
              >
                Upload Scripts
              </Button>
              {selectedFile && (
                <p className="selected-file-text">
                  Selected file: {selectedFile.name}
                </p>
              )}
            </Box>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default ScriptDetails;

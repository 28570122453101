import React, { useState } from "react";
import UploadScriptsInstructions from "./Instructions/uploadScriptsInstructions";

const UploadScripts = () => {
  const [uploadStarted, setUploadStarted] = useState(false); // State to track if the upload has started

  const startScriptUpload = () => {
    setUploadStarted(true);
  };

  return (
    <div className="uploading-container">
        <UploadScriptsInstructions onStartUpload={startScriptUpload} />
    </div>
  );
};

export default UploadScripts;

import getConfig from "../../config/index"; // Adjust the path as necessary

const config = getConfig();

export const CourseAssessmentResultsApiService = {
    // Fetch all CourseAssessmentResults
    async getAllCourseAssessmentResults(token) {
        try {
            const response = await fetch(`${config.api.base_url}/course-assessment-results/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch course assessment results');
            }

            return await response.json();  // Return the parsed response to handle in the frontend
        } catch (error) {
            console.error('Error fetching course assessment results:', error);
            throw error;
        }
    },

    // Fetch CourseAssessmentResults by course_id
    async getCourseAssessmentResultsByCourse(courseId, token) {
        try {
            const response = await fetch(`${config.api.base_url}/course-assessment-results/by-course/${courseId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch course assessment results by course');
            }

            return await response.json();  // Return the parsed response to handle in the frontend
        } catch (error) {
            console.error('Error fetching course assessment results by course:', error);
            throw error;
        }
    },


    // Create a new CourseAssessmentResults entry
    async createCourseAssessmentResults(resultsData, token) {
        try {
            const response = await fetch(`${config.api.base_url}/course-assessment-results/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(resultsData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create course assessment results');
            }

            return await response.json();  // Return the parsed response to handle in the frontend
        } catch (error) {
            console.error('Error creating course assessment results:', error);
            throw error;
        }
    },

    // // Update an existing CourseAssessmentResults entry
    // async updateCourseAssessmentResults(resultId, updatedData, token) {
    //     try {
    //         const response = await fetch(`${config.api.base_url}/course-assessment-results/${resultId}/`, {
    //             method: 'PUT',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Token ${token}`,
    //             },
    //             body: JSON.stringify(updatedData)
    //         });
    //
    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.error || 'Failed to update course assessment results');
    //         }
    //
    //         return await response.json();  // Return the parsed response to handle in the frontend
    //     } catch (error) {
    //         console.error('Error updating course assessment results:', error);
    //         throw error;
    //     }
    // },

    // Delete an existing CourseAssessmentResults entry
    async deleteCourseAssessmentResults(resultId, token) {
        try {
            const response = await fetch(`${config.api.base_url}/course-assessment-results/${resultId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete course assessment results');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error deleting course assessment results:', error);
            throw error;
        }
    },
     async updateCourseAssessmentResults(resultId, updatedData, token) {
        try {
            const response = await fetch(`${config.api.base_url}/course-assessment-results/${resultId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(updatedData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update course assessment results');
            }

            return await response.json();  // Return the parsed response to handle in the frontend
        } catch (error) {
            console.error('Error updating course assessment results:', error);
            throw error;
        }
    },
     // Update assessment stats by courseAssessmentId
    async updateAssessmentStats(courseAssessmentId, token) {
        try {
        const response = await fetch(`${config.api.base_url}/course-assessment-results/${courseAssessmentId}/update-assessment-stats/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update assessment stats');
            }

            return await response.json();  // Return the parsed response to handle in the frontend
        } catch (error) {
            console.error('Error updating assessment stats:', error);
            throw error;
        }
    },
};

export default CourseAssessmentResultsApiService;
